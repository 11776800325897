import { FC } from 'react';
import { PineyeContainer } from '../css';
import { ArrowRightOutline } from 'shared/components/icons';
import { ILevelResponse } from 'api/v2/levels';

interface PineyeProps {
	activeLevel: number;
	onChangeActiveLeague: (type: 'NEXT' | 'PREV') => void;
	data: ILevelResponse[];
}

const Pineye: FC<PineyeProps> = ({ activeLevel, onChangeActiveLeague, data }) => {
	return (
		<PineyeContainer>
			<img src={`/img/pineye/Level_1_.webp`} alt='' className={`character ${activeLevel === 1 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_2_.webp`} alt='' className={`character ${activeLevel === 2 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_3_.webp`} alt='' className={`character ${activeLevel === 3 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_4_.webp`} alt='' className={`character ${activeLevel === 4 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_5_.webp`} alt='' className={`character ${activeLevel === 5 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_6_.webp`} alt='' className={`character ${activeLevel === 6 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_7_.webp`} alt='' className={`character ${activeLevel === 7 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_8_.webp`} alt='' className={`character ${activeLevel === 8 ? 'active' : ''}`} draggable={false} />
			<img src={`/img/pineye/Level_9_.webp`} alt='' className={`character ${activeLevel === 9 ? 'active' : ''}`} draggable={false} />
			<img
				src={`/img/pineye/Level_10_.webp`}
				alt=''
				className={`character ${activeLevel === 10 ? 'active' : ''}`}
				draggable={false}
			/>

			<div className={`character_light style_${activeLevel}`}></div>
			<div className='arow_container'>
				<div className='circle'>
					<ArrowRightOutline
						className={`arrow_left ${activeLevel === 1 ? 'disable' : ''}`}
						onClick={() => {
							onChangeActiveLeague('PREV');
						}}
					/>
				</div>
				<div className='circle'>
					<ArrowRightOutline
						className={`arrow_right ${activeLevel === 10 ? 'disable' : ''}`}
						onClick={() => {
							onChangeActiveLeague('NEXT');
						}}
					/>
				</div>
			</div>
			<div className='title'>{data[activeLevel - 1].title} League</div>
		</PineyeContainer>
	);
};

export default Pineye;
