import { FC } from 'react';
import { TimerContainer } from '../css';
import { TimerVector } from '../img';
 
const TimerFixed: FC<{ date: number }> = ({ date }) => {

	let days: number | string = Math.floor(date / (1000 * 60 * 60 * 24));
	let hours: number | string = Math.floor((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	let minutes: number | string = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60));
	let seconds: number | string = Math.floor((date % (1000 * 60)) / 1000);
	if (days < 0) {
		days = 0;
	}
	if (hours < 0) {
		hours = 0;
	}
	if (minutes < 0) {
		minutes = 0;
	}
	if (seconds < 0) {
		seconds = 0;
	}
	if (days <= 9) {
		days = `0${days}`;
	}
	if (hours <= 9) {
		hours = `0${hours}`;
	}
	if (minutes <= 9) {
		minutes = `0${minutes}`;
	}
	if (seconds <= 9) {
		seconds = `0${seconds}`;
	}
	return (
		<TimerContainer>
			<div className='timer_scope'>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{minutes.toString()[0]}</div>
				</div>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{minutes.toString()[1]}</div>
				</div>
			</div>
			<div className='colon'>:</div>
			<div className='timer_scope'>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{seconds.toString()[0]}</div>
				</div>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{seconds.toString()[1]}</div>
				</div>
			</div>
		</TimerContainer>
	);
};

export default TimerFixed;
