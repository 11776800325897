import { useEffect, useState } from 'react';
import { CardHistoryContainer } from './css';
import { GETCardHistory, IGetCardHistoryResponse } from 'api/v1/card-game';
import Button from 'shared/components/button';
import { useNavigate } from 'react-router-dom';
import Loader from 'shared/components/loader';

const CardHistory = () => {
	const [cardHistory, setCardHistory] = useState<IGetCardHistoryResponse['levels']>([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		GETCardHistory().then(res => {
			setCardHistory(res.levels);

			const imageUrls = [
				'/img/game-card/background.webp',
				'/img/game-card/line.webp',
				'/img/game-card/yes.webp',
				'/img/game-card/no.webp',
				'/img/game-card/passed.webp',
				...res.levels.flatMap(level => level.sets.map(card => card.answer.imageUrl)),
			];

			Promise.all(
				imageUrls.map(
					src =>
						new Promise(resolve => {
							const img = new Image();
							img.src = src;
							img.onload = resolve;
							img.onerror = resolve;
						}),
				),
			).then(() => setIsLoading(false));
		});
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<CardHistoryContainer>
			<img src='/img/game-card/background.webp' className='background' alt='card-history' />
			<h1>Card History</h1>
			{cardHistory.map((item, index) => (
				<div className='levels' key={`${index}-card-history`}>
					<img src='/img/game-card/line.webp' className='line' alt='card-history' />
					<h2>{item.title}</h2>
					<div className='items' key={`${index}-card-history-item`}>
						{item.sets.map((card, index) => (
							<div className='item' key={`${index}-card-history-item`}>
								<img src={card.answer.imageUrl} alt='' className='card_img' />
								{card.userResponse.hasResponded && card.userResponse.isCorrectAnswer && (
									<img src='/img/game-card/yes.webp' alt='' className='status' />
								)}
								{card.userResponse.hasResponded && !card.userResponse.isCorrectAnswer && (
									<img src='/img/game-card/no.webp' alt='' className='status' />
								)}
								{!card.userResponse.hasResponded && <img src='/img/game-card/passed.webp' alt='' className='passed' />}
							</div>
						))}
					</div>
				</div>
			))}
			<div style={{ width: '100%', height: '200px' }} />
			<Button variant='primary' onClick={() => navigate('/card-game')} className='start_btn'>
				Start Quest
			</Button>
		</CardHistoryContainer>
	);
};

export default CardHistory;
