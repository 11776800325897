import { IGetWeeklyPractice } from 'api/v1/prana-game';
import { FC, useState } from 'react';
import { PracticeCardContainer } from '../css';
import numberUtils from 'shared/utils/number';
// import { useAppSelector } from 'shared/hooks/redux-helper';
import PracticeModal from './practice-modal';

interface IPracticeCardProps {
	data: IGetWeeklyPractice['practices'];
	setRefresh: (refresh: number) => void;
	refresh: number;
}

const PracticeCard: FC<IPracticeCardProps> = ({ data, setRefresh, refresh }) => {
	const [modalStatus, setModalStatus] = useState<{ status: boolean }>({ status: false });
	const [selectedPractice, setSelectedPractice] = useState<IGetWeeklyPractice['practices'][0]['days'][0]>();

	return (
		<PracticeCardContainer>
			{[...data]
				.filter(practice => practice.status === 'doing')
				.sort((a, b) => a.weekNo - b.weekNo)
				.map((peractices, i) => (
					<div className='grouped'>
						<div className='collection' key={`${i}-${peractices.weekNo}`}>
							<h5>
								{peractices.weekNo}
								{peractices.weekNo === 1
									? 'st'
									: peractices.weekNo === 2
									? 'nd'
									: peractices.weekNo === 3
									? 'rd'
									: 'th'}{' '}
								Week - {peractices.weekTitle}
							</h5>
							{peractices.days
								.sort((a, b) => {
									const order: { [key: string]: number } = { pending: 1, losted: 2, done: 3 };
									return order[a.claimedStatus] - order[b.claimedStatus];
								})
								.map((peractice, index) => (
									<div
										key={`${index}-${peractice.title}-${peractice.id}`}
										className='border'
										onClick={() => {
											if (!peractice.isCurrent || peractice.claimedStatus !== 'pending') {
												return;
											}
											setSelectedPractice(peractice);
											if (peractice.claimedStatus === 'pending') {
												setModalStatus({ status: true });
											}
										}}
									>
										{(peractice.claimedStatus === 'losted' || peractice.claimedStatus === 'pending') &&
											!peractice.isCurrent && (
												<div className='lock_caption'>
													<img src='/img/shop/lock.webp' />
												</div>
											)}
										{peractice.claimedStatus === 'done' && (
											<div className='bought'>
												<img src='/img/shop/bought.webp' className='tick' />
											</div>
										)}
										<div className='container_timer'>
											<img src={peractice.icon} alt='' className='clock' />
										</div>
										<div className='content'>
											<p>{peractice.title}</p>
											<div>
												<p>
													Profit: <span>{numberUtils.formatPriceWithDecimals(peractice.profit)}</span>
												</p>
												<p className='cost'>
													{/* 
													Cost: <img src='/img/dollar.webp' alt='' />
													{numberUtils.formatPriceWithDecimals(peractice.cost)} */}
												</p>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				))}
			{[...data]
				.filter(practice => practice.status === 'todo')
				.sort((a, b) => a.weekNo - b.weekNo)
				.map((peractices, i) => (
					<div className='grouped_colaps'>
						<div className='collection' key={`${i}-${peractices.weekNo}`}>
							<h5>
								{peractices.weekNo}
								{peractices.weekNo === 1
									? 'st'
									: peractices.weekNo === 2
									? 'nd'
									: peractices.weekNo === 3
									? 'rd'
									: 'th'}{' '}
								Week - {peractices.weekTitle}
							</h5>
							<div>
								<img src='/img/shop/lock.webp' />
							</div>
						</div>
					</div>
				))}
			{[...data]
				.filter(practice => practice.status === 'done')
				.sort((a, b) => a.weekNo - b.weekNo)
				.map((peractices, i) => (
					<div className='grouped_colaps'>
						<div className='collection' key={`${i}-${peractices.weekNo}`}>
							<h5>
								{peractices.weekNo}
								{peractices.weekNo === 1
									? 'st'
									: peractices.weekNo === 2
									? 'nd'
									: peractices.weekNo === 3
									? 'rd'
									: 'th'}{' '}
								Week - {peractices.weekTitle}
							</h5>
							<div className=''>
								<img src='/img/shop/bought.webp' className='tick' />

							</div>
						</div>
					</div>
				))}
			{modalStatus.status && selectedPractice && (
				<PracticeModal
					setRefresh={() => setRefresh(refresh + 1)}
					data={selectedPractice}
					setStatus={status => setModalStatus({ status })}
					status={modalStatus.status}
				/>
			)}
		</PracticeCardContainer>
	);
};
export default PracticeCard;
