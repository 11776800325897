import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LevelProgressContainer = styled.section(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 12px;
		color: ${theme.colors.text.primary};
		background: #3caff475;

		position: fixed;
		bottom: -22px;
		width: 97%;
		padding: 0 24px 120px;
		border: 2px solid #ffffff4a;
		border-radius: 40px 40px 0 0px;
		user-select: none;
		left: 0;
		right: 0;
		margin: 0 auto;
		height: 250px;
		.flower_container {
			display: flex;
			align-items: center;
			gap: 10px;
			.progress_container {
				grid-column: 1/3;
				width: -webkit-fill-available;
				height: 30px;
				border-radius: 30px;
				background: linear-gradient(227.14deg, #7dcdff -1.29%, #1fa1f0 29.94%, #0074da 87.8%);
				margin: 12px auto 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px 11px;
				box-shadow: 0px 1px 4px 0px #ffffffb8 inset;
				box-shadow: 0px 2px 11px 0px #0000004a;
				.border {
					width: 100%;
					height: 14px;
					border-radius: 13px;
					background: #0958a8;
					box-shadow: 0px 2px 4px 0px #ffffffc2 inset, 0px 0px 6px 0px #000000;
				}

				.filled {
					height: 100%;
					border-radius: 12px;
					width: 0;
					transition: all 300ms ease;
					position: relative;
					background: linear-gradient(90deg, #6800d0 7.01%, #902bf5 31.49%, #5e6cff 63.44%, #54beff 97.94%);
					box-shadow: 0px 2px 4px 0px #ffffff inset, 0px 4px 4px 0px #002fec24;
				}
			}
		}

		.info {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr;
			gap: 8px;
			align-items: center;
			justify-content: space-between;
			div {
				padding: 1px;
				background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				border-radius: 6px;
				.level,
				.energy,
				.boost {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
					gap: 8px;
					background: #0d7dc2;
					border-radius: 5px;
					height: 41px;
					padding: 10px 5px;
					color: #fff;
					img {
						width: 25px;
						height: 25px;
					}
				}
				.level {
					gap: 3px;
					padding-right: 10px;
					align-items: end;
					text-wrap: nowrap;
					img {
						width: 21px;
						height: 21px;
					}
				}
				.energy {
					gap: 5px;
					p {
						width: 65px;
						font-size: 13px;
						font-weight: 400;
						line-height: 17px;
					}
				}
			}
		}
	`,
);
