import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalContainer = styled.div<{ $isBottomSheet?: boolean }>(
	({ $isBottomSheet, theme }) => css`
		display: flex;
		width: 100%;
		height: 100vh;
		align-items: center;
		justify-content: center;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 10;
		.background {
			background: rgba(0, 0, 0, 0.38);
			height: 100vh;
			width: 100%;
			backdrop-filter: blur(1px);
			position: fixed;
			cursor: pointer;

			&.active {
				animation: modal_fade_in 0.4s ease;
			}

			&.deactive {
				animation: modal_fade_out 0.4s ease forwards;
			}
		}

		.modal_panel {
			background-color: #112336;
			position: relative;
			border-radius: 8px;
			margin: 0 24px;
			width: 100%;
			${$isBottomSheet &&
			'background: linear-gradient(180deg, #0C5C8F 64.11%, #031A29 100%); position: absolute; bottom:0; border-radius: 2rem 2rem 0 0; border-radius: 28px 28px 0px 0px; border-top: 2px solid rgba(255, 255, 255, 0.91); background: #0D5D8F; box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.63) inset; '};
			${$isBottomSheet &&
			`
				&.active {
					animation: modal_panel_slide_up 0.3s ease
				}

				&.deactive {
					animation: modal_panel_slide_down 0.3s ease forwards
				}
			`}

			${!$isBottomSheet &&
			`
				&.active {
					animation: modal_panel_scale_in 0.3s ease
				}

				&.deactive {
					animation: modal_panel_scale_out 0.3s ease forwards
				}
			`}

		@keyframes modal_panel_slide_up {
				0% {
					transform: translateY(100%);
				}
				100% {
					transform: translateY(0);
				}
			}

			@keyframes modal_panel_slide_down {
				0% {
					transform: translateY(0);
				}
				100% {
					transform: translateY(100%);
				}
			}

			/* &.active {
				animation: modal_panel_scale_in 0.3s ease;
			}

			&.deactive {
				animation: modal_panel_scale_out 0.3s ease forwards;
			} */

			.header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 24px 24px 20px;
				color: ${theme.colors.text.primary};
				gap: 4px;

				h3 {
					font-weight: 600;
					font-size: 24px;
					margin: 0;
					line-height: 30px;
				}

				svg {
					width: 16px;
					height: 16px;
				}
			}
		}

		@keyframes modal_fade_in {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@keyframes modal_fade_out {
			0% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}

		@keyframes modal_panel_scale_in {
			0% {
				scale: 0;
			}
			100% {
				scale: 1;
			}
		}

		@keyframes modal_panel_scale_out {
			0% {
				scale: 1;
			}
			100% {
				scale: 0;
			}
		}
	`,
);
