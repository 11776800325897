import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ShopPranaContainer = styled.div(
	() => css`
		color: #fff;
		position: relative;
		padding: 19px;
		height: inherit;
		.left_top_light {
			background: #84b6d7;
			width: 124px;
			height: 195px;
			position: absolute;
			top: 0px;
			left: -56px;
			border-radius: 20%;
			filter: blur(43px);
			z-index: 1;
		}
		.right_top_light {
			background: #84b6d7;
			width: 124px;
			height: 195px;
			position: absolute;
			top: 0px;
			right: -56px;
			border-radius: 20%;
			filter: blur(43px);
			z-index: 1;
		}
		.bottom_light {
			background: #84b6d7;
			width: 100%;
			height: 70px;
			position: absolute;
			top: 140px;
			left: 0;
			right: 0;
			margin: auto;
			border-radius: 20%;
			filter: blur(35px);
			z-index: 1;
		}
		.banner {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 170px;
		}
		.header {
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			z-index: 3;
			.how {
				position: absolute;
				right: 0;
				left: 0;
				top: 120px;
				margin: auto;
				background: linear-gradient(274.5deg, #03dcce 11.65%, #3893cb 57.17%);
				box-shadow: 0px 4px 4px 0px #ffffffa1 inset;
				font-size: 16px;
				font-weight: 500;
				line-height: 20px;
				padding: 12px 22px 8px;
				border-radius: 86px;
				width: 144px;
				color: #fff;
				z-index: 2;
				a {
					text-decoration: none;
					color: #fff;
				}
			}
			.coins_container {
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 7px;

				.coin {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 4px;
					font-size: 18px;
					font-weight: 600;
					line-height: 22.28px;

					img {
						width: 21px;
						height: 21px;
						margin-bottom: 3px;
					}
				}
			}
		}
		@media (max-width: 350px) {
			.banner {
				height: 140px;
			}
			.header {
				.how {
					top: 90px;
					font-size: 12px;
					width: 120px;
				}
				.coins_container {
					.coin {
						font-size: 14px;
					}
				}
			}
		}
	`,
);
