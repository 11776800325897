import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RankingContainer = styled.section(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin: 0 8px 9px;
		border: 2px solid #ffffff4a;
		border-radius: 30px 30px 0 0px;
		padding: 17px 24px 110px;
		overflow-y: auto;
		border-bottom: unset;
		background: linear-gradient(180deg, #1b87c9 58.4%, rgba(28, 136, 202, 0) 92.44%);
 
		.info {
			border-radius: 48px;
			padding: 1px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			.border {
				background: #1d72a5;
				box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
				border-radius: 47px;
				display: flex;
				justify-content: space-around;
				align-items: center;
				padding: 13px 20px 12px;
				font-size: 12px;
				font-weight: 500;
				line-height: 17px;
				div {
					text-align: center;
					height: 100%;
				}

				div:nth-child(2) {
					border-right: 1px solid #ffffff;
					border-left: 1px solid #ffffff;
					padding: 0 20px;
				}
			}
		}
		&.user_rank {
			position: fixed;
			bottom: 114px;
			left: 0;
			width: 100%;
			background: #ffffff14;
			backdrop-filter: blur(8px);
			padding: 8px 24px;
		}

		.rank_item {
			border-radius: 9px;
			padding: 1px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);

			.border {
				display: grid;
				grid-template-columns: 24px 32px 1fr;
				/* grid-template-rows: auto 1fr; */
				gap: 4px 12px;
				padding: 10px;
				border-radius: 8px;
				position: relative;
				background: #126090;
				overflow: hidden;
				.number {
					grid-column: 1;
					grid-row: 1 / 3;
					text-align: center;
					align-items: center;
					display: flex;
					justify-content: end;
				}
				.img_container {
					grid-column: 2;
					grid-row: 1/3;
					border-radius: 4px;
					height: 32px;
					width: 32px;
					margin: auto;
				}

				.username {
					color: ${theme.colors.text.primary};
					margin: auto auto 0 0;
					font-weight: 600;
					font-size: 14px;
					line-height: 17px;
				}
				.balance {
					margin: 0 auto auto 0;
					color: #28c7f3;

					display: flex;
					align-items: center;
					gap: 6px;

					span {
						font-weight: 400;
						font-size: 12px;
						line-height: 15px;
						margin-top: 2px;
					}
					img {
						width: 14px;
					}
				}

				.right_light {
					position: absolute;
					width: 245px;
					height: 245px;
					right: -188px;
					top: -105px;
					border-radius: 245px;
					filter: blur(40px);
					/* top: -95px; */
				}
			}
		}

		.rank {
			border: 1px solid #6496f9;
			border-radius: 4px;
			padding: 12px 12px 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			position: relative;

			.right_light {
				position: absolute;
				width: 245px;
				height: 245px;
				right: -188px;
				top: -105px;
				border-radius: 245px;
				filter: blur(40px);
				/* top: -95px; */
			}

			.username {
				display: flex;
				gap: 4px;
				color: #fff;
				font-weight: 600;
				font-size: 14px;
			}
			.balance {
				color: ${theme.colors.text.primary};
				margin: 0 0 auto auto;
				display: flex;
				align-items: center;
				gap: 6px;
				position: relative;
				z-index: 2;

				span {
					font-weight: 400;
					font-size: 12px;
					line-height: 15px;
					margin-top: 2px;
				}
				img {
					width: 14px;
				}
			}
		}
		@media (max-height: 667px) {
			.info {
				.border {
				 
				 
					div:nth-child(2) {
						padding: 0 16px;
						span {
							display: block;
							width: 100%;
						}
					}
				}
			}
		}

		@media (max-width: 370px){
			.info {
				.border {
				 
					div:nth-child(1) {
						padding: 0 2px 0 0;
					}
					div:nth-child(2) {
						padding: 0 8px;
						span {
							display: block;
							width: 100%;
						}
					}
					div:nth-child(3) {
						padding: 0 0 0 2px;
					}
				}
			}
		}
	`,
);
