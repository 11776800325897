import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GamesContainer } from './css';
import Button from 'shared/components/button';
import InfoModal from './components/info-modal';
import numberUtils from 'shared/utils/number';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { GETPranaGameInfo, IGetPranaGameInfo } from 'api/v1/prana-game';
import { useDispatch } from 'react-redux';
import { changePranaLevel, changePranaProit } from 'store/reducers/prana';
import Loader from 'shared/components/loader';
import { useNavigate } from 'react-router-dom';

const Games = () => {
	const [statusModal, setStatusModal] = useState(false);
	const [type, setType] = useState('');
	const [info, setInfo] = useState<IGetPranaGameInfo | null>(null);
	const [currentLevel, setCurrentLevel] = useState<string | null>(null);
	const balance = useAppSelector(state => state.profile.balance);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setInfo(null);
		GETPranaGameInfo().then(res => {
			setInfo(res);
			dispatch(changePranaLevel(res));
			dispatch(changePranaProit(res));
		});
	}, []);

	useEffect(() => {
		if (info) {
			const charLevel = info.categories.filter(i => i.title === 'Character')[0].userLevel;
			const finLevel = info.categories.filter(i => i.title === 'Finance')[0].userLevel;

			if (finLevel === charLevel) {
				setCurrentLevel(`${charLevel}-${finLevel}`);
			} else {
				setCurrentLevel(`${Math.min(charLevel, finLevel)}-${Math.min(charLevel, finLevel) + 1}`);
			}
		}
	}, [info]);

	const imagesChakras = [
		{
			active: '/img/game/chakra-1.webp',
			inactive: '/img/game/chakra-1-inactive.webp',
			id: 1,
		},
		{
			active: '/img/game/chakra-2.webp',
			inactive: '/img/game/chakra-2-inactive.webp',
			id: 2,
		},
		{
			active: '/img/game/chakra-3.webp',
			inactive: '/img/game/chakra-3-inactive.webp',
			id: 3,
		},
		{
			active: '/img/game/chakra-4.webp',
			inactive: '/img/game/chakra-4-inactive.webp',
			id: 4,
		},
		{
			active: '/img/game/chakra-5.webp',
			inactive: '/img/game/chakra-5-inactive.webp',
			id: 5,
		},
		{
			active: '/img/game/chakra-6.webp',
			inactive: '/img/game/chakra-6-inactive.webp',
			id: 6,
		},
		{
			active: '/img/game/chakra-7.webp',
			inactive: '/img/game/chakra-7-inactive.webp',
			id: 7,
		},
	];
	return (
		<>
			<GamesContainer>
				<Helmet>
					<title>PinEye | prana</title>
				</Helmet>

				{info === null && <Loader />}
				{info && (
					<>
						<div className='header'>
							<div
								className='level'
								onClick={() => {
									setStatusModal(true);
									setType('Prana');
								}}
							>
								<img src='/img/game/star.webp' alt='' />
								<div className='inner'>
									Prana <br /> Level {info.level}
									<img src='/img/game/info.webp' alt='' />
								</div>
								<div className='divider-h' />
							</div>
							<div className='dividerr' />
							<div
								className='level'
								onClick={() => {
									setStatusModal(true);
									setType('Profit');
								}}
							>
								<img src='/img/profit.webp' alt='' />
								<div className='inner'>
									Profit per hour <br />
									{info.profit}
									<img src='/img/game/info.webp' alt='' />
								</div>
							</div>
							<div className='dividerr' />
							<div className='level'>
								<img src='/img/game/coin.webp' alt='' />
								<div className='inner'>
									Balance
									<br />
									{numberUtils.formatPriceWithDecimals(balance)}
								</div>
								<div className='divider-h' />
							</div>
						</div>
						<img className='background' src='/img/game/prana-back.webp' alt='' />
						<div className='man_container'>
							<img className='man' src='/img/game/man.webp' alt='' />
							{currentLevel !== null && <img className='triangle' src={`/img/game/triangle-${currentLevel}.webp`} alt='' />}
							<div className='chakras'>
								{imagesChakras.map(item => (
									<img
										src={
											info.level === 0
												? item.id === 1
													? item.inactive
													: item.inactive
												: info.level === item.id
												? item.active
												: item.inactive
										}
										className={item.id === info.level ? 'active' : ''}
										alt=''
									/>
								))}
							</div>
						</div>
						<Button
							id='prana_enhance'
							variant='primary'
							className='btn'
							onClick={() => {
								navigate('/games/prana/shop');
							}}
						>
							<img src='/img/game/buda-btn.webp' className='buda' />
							<p>Enhance Prana</p>
						</Button>
					</>
				)}
			</GamesContainer>
			{statusModal && type.length > 0 && (
				<InfoModal setStatus={setStatusModal} setType={() => setType('')} type={type} status={statusModal} />
			)}
		</>
	);
};

export default Games;
