import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Special, Tab, Academy, Partner } from './components';
import { EarnContainer } from './css';
import { GETSocial, ISocialResponse } from 'api/v1/social';
import { GETDailyRewards, IDailyRewardResponse } from 'api/v1/daily-reward';
import { useAppSelector } from 'shared/hooks/redux-helper';

const Earn = () => {
	const [activeTab, setActiveTab] = useState<'SPECIAL' | 'PARTNERS' | 'ACADEMY'>('SPECIAL');
	const [socialData, setSocialData] = useState<ISocialResponse[] | null>(null);
	const [academyData, setAcademyData] = useState<ISocialResponse[] | null>(null);
	const [dailyData, setDailyData] = useState<IDailyRewardResponse | null>(null);
	const [remainSocial, setRemainSocial] = useState<number>(0);
	const [remainAcademy, setRemainAcademy] = useState<number>(0);
	const [remainPartners, setRemainPartners] = useState<number>(0);

	const replay = useAppSelector(state => state.profile.replay);

	useEffect(() => {
		setSocialData(null);
		setDailyData(null);
		GETSocial().then(res => {
			setSocialData(res);
			setAcademyData(res.filter(item => item.category === 'Academy'));
			setRemainSocial(res.filter(item => !item.isClaimed && item.category === 'Special' && item.subCategory !== 'Partners').length);
			setRemainAcademy(res.filter(item => !item.isClaimed && item.category === 'Academy').length);
			setRemainPartners(res.filter(item => !item.isClaimed && item.category === 'Special' && item.subCategory === 'Partners').length);
		});

		GETDailyRewards().then(res => {
			setDailyData(res);
			// if (res.canClaim) {
			// 	setRemainSocial(prevRemainSocial => prevRemainSocial + 1);
			// }
		});
	}, [replay]);
	return (
		<EarnContainer>
			<Helmet>
				<title>PinEye | earn</title>
			</Helmet>
			<div className='top_light'></div>
			{/* <Header /> */}
			<Tab
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				remainPartners={remainPartners}
				remaimainSocial={remainSocial}
				remainAcademy={remainAcademy}
			/>
			{activeTab === 'SPECIAL' && socialData && dailyData && (
				<Special setDailyData={setDailyData} setSocialData={setSocialData} socialData={socialData} dailyData={dailyData} />
			)}
			{activeTab === 'ACADEMY' && academyData && <Academy setAcademyData={setAcademyData} academyData={academyData} />}
			{activeTab === 'PARTNERS' && (
				<Partner setDailyData={setDailyData} setSocialData={setSocialData} socialData={socialData} dailyData={dailyData} />
			)}
		</EarnContainer>
	);
};

export default Earn;
