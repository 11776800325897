import { FC, useState } from 'react';
import { TabsContainer } from '../css';

interface TabProps {
	setSelectedTabs: (tab: 'Character' | 'Finance' | 'Practice') => void;
}
const Tabs: FC<TabProps> = ({ setSelectedTabs }) => {
	const [activeTab, setActiveTab] = useState(0);

	const tabs = ['Character', 'Finance', 'Practice'];

	return (
		<TabsContainer>
			{tabs.map((tab, index) => (
				<div
					key={tab}
					className={`tab_item ${activeTab === index ? 'active' : index === activeTab - 1 ? 'without_border' : ''}`}
					id={tab}
					onClick={() => {
						setActiveTab(index);
						setSelectedTabs(tab as 'Character' | 'Finance' | 'Practice');
					}}
				>
					{tab}
				</div>
			))}
		</TabsContainer>
	);
};

export default Tabs;
