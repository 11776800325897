import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'shared/css/reset.css';
import store from 'store/index';
import App from './app';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const instance = createInstance({
	urlBase: '//analytics.propsfund.com/',
	siteId: 2,
	trackerUrl: 'https://analytics.propsfund.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
	disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	linkTracking: false, // optional, default value: true
	configurations: {
		// optional, default value: {}
		// any valid matomo configuration, all below are optional
		disableCookies: true,
		setRequestMethod: 'POST',
	},
});

ReactDOM.createRoot(document.getElementById('root')!).render(
	<Provider store={store}>
		{/* @ts-ignore */}
		<MatomoProvider value={instance}>
			<App />
		</MatomoProvider>
	</Provider>,
);
