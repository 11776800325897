import { useNavigate } from 'react-router-dom';
import { GamesContainer } from '../css';
// import toast from 'react-hot-toast';
// import { canClaimReward } from 'api/v1/secret-code';

const Games = () => {
	const navigate = useNavigate();

	const handleCardGame = () => {
		navigate('/card-game');
	};
	const data = [
		{
			title: 'Code',
			id: 'secret_code',
			pic: '/img/game/secret_code_popup.webp',
			onclick: () => {
				navigate('/games/secret-code');
			},
		},
		{ title: 'Lottery', id: 'lottery', pic: '/img/game/lottery.webp', onclick: () => navigate('/games/lottery') },
		{ title: 'Card', id: 'card_game', pic: '/img/game/plan-1.webp', onclick: handleCardGame },
	];
	return (
		<GamesContainer>
			{data.map((item, index) => (
				<div className='border' id={item.id} key={`${index}-${item.title}`} onClick={item.onclick}>
					<div className='main'>
						<img src={item.pic} alt='' />
						<p>{item.title}</p>
					</div>
				</div>
			))}
		</GamesContainer>
	);
};

export default Games;
