import { FC, useEffect, useState } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { BuyModalContainer } from '../css';
import numberUtils from 'shared/utils/number';
import { ICards, IGetPranaMarketplace, POSTPranaPurch } from 'api/v1/prana-game';
import { changeBalance } from 'store/reducers/profile';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { changePranaProit } from 'store/reducers/prana';

const BuyModal: FC<{
	id: { cardId: number; collectionId: number; categoryId: number; level: number } | null;
	status: boolean;
	data: IGetPranaMarketplace['categories'];
	setStatus: (status: boolean) => void;
	setRefresh: () => void;
}> = ({ data, status, setStatus, id, setRefresh }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedItemForBuy, setSelectedItemForBuy] = useState<ICards | null>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (data !== undefined) {
			const selectedCard = data
				.find(category => category.id === id?.categoryId)
				?.collections.find(collection => collection.id === id?.collectionId)
				?.cards.find(card => card.id === id?.cardId);

			if (selectedCard) {
				setSelectedItemForBuy(selectedCard);
			}
		}
	}, [data, id]);

	const onClaimClickHandler = () => {
		if (!isLoading && id && selectedItemForBuy) {
			setIsLoading(true);
			POSTPranaPurch(id.cardId, id.level + 1)
				.then(res => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					toast.success(' Added successfully');
					dispatch(changeBalance(res));
					dispatch(changePranaProit(res));
					onClose();
					setRefresh();
				})
				.catch(() => {
					onClose();
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			onClose();
		}
	};

	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<BuyModalContainer>
				{selectedItemForBuy && <img src={selectedItemForBuy.imageUrl} alt='' className='calender' />}
				<div className='content'>
					<h3>{selectedItemForBuy?.title}</h3>
					<p>
						Profit:
						<span className='green_text'> +{selectedItemForBuy?.profit} </span>
					</p>
					<p>Enhance Your Prana</p>
					<Button variant='primary' className='btn' onClick={onClaimClickHandler} isLoading={isLoading}>
						<>
							Buy{' '}
							{selectedItemForBuy?.title && ['a', 'e', 'i', 'o', 'u'].includes(selectedItemForBuy.title[0].toLowerCase())
								? 'an'
								: 'a'}{' '}
							{selectedItemForBuy?.title} (
							<img src='/img/dollar.webp' alt='' />
							{numberUtils.formatPriceWithDecimals(selectedItemForBuy?.cost ?? 0)})
						</>
					</Button>
				</div>
			</BuyModalContainer>
		</Modal>
	);
};
export default BuyModal;
