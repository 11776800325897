import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardGameContainer = styled.section(
	({ theme }) => css`
		position: relative;
		color: ${theme.colors.text.primary};
		padding: 0 16px 120px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		.background {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: -webkit-fill-available;
			object-fit: cover;
			z-index: 1;
		}
		h1 {
			font-size: 20px;
			font-weight: 600;
			line-height: 24.76px;
			text-align: center;
			position: relative;
			z-index: 2;
			padding-top: 16px;
		}
		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 19.41px;
			text-align: center;
			z-index: 2;
			position: relative;
		}
		.box {
			position: relative;
			z-index: 2;
			padding: 29px 5px 5px;
			border-radius: 6px;
			border: 1.2px solid #ffffff94;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 8px;
			.q {
				position: absolute;
				top: -10px;
				left: 0;
				right: 0;
				margin: auto;
				width: 30px;
				height: 35px;
			}
			h3 {
				font-size: 18px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
			}
			h4 {
				font-size: 16px;
				font-weight: 500;
				line-height: 20px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;
				img {
					width: 21px;
					height: 21px;
					margin-bottom: 4px;
				}
			}
			.line {
				width: 90%;
				height: 2px;
				background-image: linear-gradient(90deg, rgba(19, 31, 122, 0) -2.39%, #ffffff 47.79%, rgba(11, 24, 118, 0) 97.96%);
				margin-top: 10px;
			}
		}

		.swiper_container {
			position: relative;
			z-index: 1;

			.swiper {
				width: 189px;
				height: 313px;
			}

			.swiper-slide {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				font-size: 22px;
				font-weight: bold;
				box-shadow: 1px 5px 11.6px 6px #000000fc;
				.yes {
					width: 80px;
					position: absolute;
					margin: auto;
					left: 0;
					right: 0;
				}
				.img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 10px;
				}
			}

			.prev {
				top: 16vh;
				position: absolute;
				width: 50px;
				background: #2373a4c2;
				height: 50px;
				border-radius: 100%;
				left: 0;
				z-index: 6;
				svg {
					width: 50%;
					height: 50%;
					color: #fff;
					transform: rotate(180deg);
					top: 12px;
					position: relative;
					left: 10px;
				}
			}
			.next {
				position: absolute;
				right: 0;
				width: 50px;
				height: 50px;
				top: 16vh;
				border-radius: 100%;
				background: #2373a4c2;
				z-index: 6;
				svg {
					width: 50%;
					height: 50%;
					color: #fff;
					top: 12px;
					position: relative;
					left: 10px;
				}
			}
		}

		.button_container {
			width: 92% !important;
			position: fixed;
			z-index: 2;
			bottom: 125px;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			display: flex;
			justify-content: space-between;

			.lets_go {
				width: 70%;
				height: 54px;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
				border-radius: 10px;
			}
			.lets_go_timer {
				background: linear-gradient(272.2deg, #6013ae 14.53%, #348c86 72.5%);
				width: 70%;
				height: 54px;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
				border-radius: 10px;
			}
			.history {
				width: 27%;
				display: flex;
				flex-direction: column;
				height: 54px;
				p {
					font-size: 14px;
					font-weight: 500;
					line-height: 17.33px;
					text-align: center;
					text-wrap: nowrap;
				}
				span {
					font-size: 9.7px;
					font-weight: 400;
					line-height: 12.01px;
					text-align: center;
					text-wrap: nowrap;
				}
			}
		}
		@media (min-height: 844px) {
			.button_container {
				/* top: 90px; */
			}
		}
	`,
);
