import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	profit: 0,
	level: 0,
};

export const Prana = createSlice({
	name: 'prana',
	initialState: initialState,
	reducers: {
		changePranaProit: (state, action: { payload: { profit: number } }) => {
			state.profit = action.payload.profit;
		},
		changePranaLevel: (state, action: { payload: { level: number } }) => {
			state.level = action.payload.level;
		},
	},
});

export const { changePranaProit, changePranaLevel } = Prana.actions;
export default Prana.reducer;
