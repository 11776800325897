import { FC, Fragment } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';
import { NotifModalContainer } from '../css';
import { useAppSelector } from 'shared/hooks/redux-helper';

const NotifModal: FC<{ status: boolean; setStatus: (status: boolean) => void }> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });

	const notifs = useAppSelector(state => state.profile.notif);

	const meta = notifs
		.filter(item => item.type !== 'LotteryReward')
		.map(item => {
			const meta = JSON.parse(item.metatData);
			return meta;
		});
	const totalReward = meta.reduce((acc, curr) => acc + (curr.Reward ? parseInt(curr.Reward) : parseInt(curr)), 0);
	return (
		<Modal status={_status} onClose={onClose} title='' disableHeader={true} isBottomSheet>
			<NotifModalContainer>
				<img src='img/referral_daily_gift.webp' alt='' className='header_img' />
				<h2>
					<img src='/img/dollar.webp' alt='' className='dollar_img' />+{numberUtils.formatPriceWithDecimals(totalReward)}
				</h2>
				<h4>Well done!</h4>
				{notifs.length === 1 &&
					notifs
						.filter(item => item.type !== 'LotteryReward')
						.map((item, index) => {
							const meta = JSON.parse(item.metatData);
							return (
								<Fragment key={`section_reward_item_${index}`}>
									{item.type === 'Welcome' && (
										<>
											<p>Welcome Bonus</p>
											<div className='reward_container'>
												<img src='/img/dollar.webp' alt='' className='dollar_img' />
												<div className='title'>
													Welcome Reward : {numberUtils.formatPriceWithDecimals(meta.Reward)}
												</div>
											</div>
										</>
									)}
									{item.type === 'DailyReferralReward' && (
										<>
											<p>
												You’ve earned {numberUtils.formatPriceWithDecimals(meta.Reward)} coins from your referrals,
												30% of their total earnings.
											</p>
										</>
									)}
									{item.type === 'ExtraReferralReward' && (
										<>
											<p>1000 more coins for each referral above 10,000 people</p>
											<div className='reward_container'>
												<img src='/img/dollar.webp' alt='' className='dollar_img' />
												<div className='title'>{numberUtils.formatPriceWithDecimals(meta.Reward)}</div>
											</div>
										</>
									)}
								</Fragment>
							);
						})}
				{notifs.length >= 2 && (
					<>
						<p>
							When you weren't actively, you accumulated
							<br />
							<br />
							<strong>Coins:</strong>
						</p>
						<table>
							<tbody>
								{notifs
									.filter(item => item.type !== 'LotteryReward')
									.map((item, index) => {
										const meta = JSON.parse(item.metatData);
										return (
											<Fragment key={`table_row_${index}`}>
												{item.type === 'ExtraReferralReward' && (
													<tr>
														<td>30% from Friends:</td>
														<td>+ {numberUtils.formatPriceWithDecimals(meta.Reward)}</td>
													</tr>
												)}
												{item.type === 'DailyReferralReward' && (
													<tr>
														<td>Invite Friends:</td>
														<td>+ {numberUtils.formatPriceWithDecimals(meta.Reward)}</td>
													</tr>
												)}
												{item.type === 'pranaProfit' && (
													<tr>
														<td>Prana Income:</td>
														<td>+ {numberUtils.formatPriceWithDecimals(item.metatData)}</td>
													</tr>
												)}
											</Fragment>
										);
									})}
							</tbody>
						</table>
					</>
				)}

				<Button variant='primary' onClick={onClose}>
					Ok
				</Button>
			</NotifModalContainer>
		</Modal>
	);
};

export default NotifModal;
