import { GETShop, IGetShopResponse, TShopDto } from 'api/v1/miner';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks/redux-helper';
import numberUtils from 'shared/utils/number';
import { Cards, Tabs } from './components';
import { ShopContainer } from './css';
import { useNavigate } from 'react-router-dom';
import Loader from 'shared/components/loader';

const Shop = () => {
	const balance = useAppSelector(state => state.profile.balance);
	const [selectedTab, setSelectedTabs] = useState<'CPU' | 'Frame' | 'GPU' | 'Power' | 'Board' | 'Fan'>('Frame');
	const [shop, setShop] = useState<IGetShopResponse | null>(null);
	const [data, setData] = useState<(IGetShopResponse[TShopDto])[]>([]);
	const [refresh, setRefresh] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		window.Telegram.WebApp.BackButton.isVisible = true;
		GETShop().then(res => {
			setShop(res);
		});

		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/games/miner');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, [refresh]);

	useEffect(() => {
		if (shop !== null) {
			const key = selectedTab.toLowerCase() as TShopDto;
			const shopData = shop[key];
			if (Array.isArray(shopData)) {
				setData(
					shopData.map(item => ({
						...item,
					})),
				);
			}
		}
	}, [selectedTab, shop, refresh]);

	return (
		<ShopContainer>
			<img src='/img/shop/banner.webp' alt='' className='banner' />
			<div className='header'>
				<div className='coins_container'>
					<div className='coin'>
						<img src='/img/dollar.webp' alt='' />
					</div>
					<p>{numberUtils.formatPriceWithDecimals(balance)}</p>
				</div>
				<div className='how'>
					<a href='https://docs.pineye.io/miner' target='_blank' rel='noreferrer'>
						How it works?
					</a>
				</div>
			</div>
			<div className='light' />
			<Tabs setSelectedTabs={setSelectedTabs} />
			{shop === null && <Loader />}
			{data && <Cards data={data} selectedTab={selectedTab} refresh={refresh} setRefresh={setRefresh} />}
		</ShopContainer>
	);
};

export default Shop;
