import requestHandler from 'shared/utils/request-handler';
import { IGetPlayersResponse, ILevelResponse } from './levels.dto';

const BASE_URL = '/api/v2/level';

export const GETLevels = async () => {
	return requestHandler.call<ILevelResponse[]>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};

export const GETPlayers = async (page: number, level: number) => {
	return requestHandler
		.call<IGetPlayersResponse[]>({ url: `${BASE_URL}/GetPlayers?page=${page}&level=${level}`, method: 'get' })
		.then(res => res.data);
};
