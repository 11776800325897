import { GETProfileAPI } from 'api/v2/profile';
import { POSTBuyBooster } from 'api/v2/profile';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import { useAppSelector } from 'shared/hooks/redux-helper';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';
import { changeProfileOverview, changeReplay, resetEnergy } from 'store/reducers/profile';
import { BoostModalContainer } from '../css';
import { POSTSetFullEnergy } from 'api/v1/full-energy';
import { FullModal, LimitModal, MultiTapVector } from '../img';
import { useNavigate } from 'react-router-dom';

const BoostModal: FC<{
	status: boolean;
	title: string;
	price?: number;
	reward?: number;
	type: 'EnergyLimit' | 'Multitap' | 'FullEnergy';
	id?: number;
	currentLevel?: number;
	setStatus: (status: boolean) => void;
}> = ({ status, currentLevel, title, type, price, id, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const balance = useAppSelector(state => state.profile.balance);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onBoostClickHandler = () => {
		setIsLoading(true);
		if (type === 'FullEnergy') {
			POSTSetFullEnergy()
				.then(() => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					dispatch(resetEnergy());
					dispatch(changeReplay());
					toast.success('booster has been activated');
					navigate('/');
				})
				.finally(() => {
					setIsLoading(false);
					onClose();
				});
		}
		if (id !== undefined) {
			POSTBuyBooster(id)
				.then(() => {
					GETProfileAPI()
						.then(res => {
							window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
							dispatch(changeReplay());
							toast.success('booster has been activated');
							dispatch(changeProfileOverview(res));
							navigate('/');
						})
						.finally(() => {
							setIsLoading(false);
							onClose();
						});
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	};

	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<BoostModalContainer>
				{type === 'FullEnergy' ? <FullModal /> : type === 'EnergyLimit' ? <LimitModal /> : <MultiTapVector className='multi' />}
				<h3>{title}</h3>

				<div className='shadow' />
				<div className='reward_container'>
					{type === 'Multitap'
						? ' Increase earnings per tap +1'
						: type === 'EnergyLimit'
						? ' Increase energy capacity by +500'
						: 'Restore your energy'}
				</div>

				<div className='price_container'>
					<img src='/img/dollar.webp' alt='' draggable={false} />
					<div className='title'>
						{price ? <p className='prize'>+ {numberUtils.formatPriceWithDecimals(price)}</p> : <p className='prize'>Free</p>}
						<span> {currentLevel !== undefined ? `| lvl ${currentLevel + 1} ` : ''}</span>
					</div>
				</div>

				<Button variant='primary' disabled={balance < (price || 0)} isLoading={isLoading} onClick={onBoostClickHandler}>
					{balance >= (price || 0) ? 'Go ahead' : 'Insufficient Balance'}
				</Button>
			</BoostModalContainer>
		</Modal>
	);
};
export default BoostModal;
