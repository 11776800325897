import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SelectLangModalContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		padding: 0 24px 32px;
		position: relative;
		text-align: center;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		z-index: 5;

		.lang_list {
			display: flex;
			flex-direction: column;
			/* gap: 16px; */
			margin-top: 16px;
			margin-bottom: 16px;
			label {
				display: flex;
				align-items: center;
				gap: 16px;
				cursor: pointer;
				border-bottom: 1px solid #73bae6;
				padding: 14px;
				&:last-child {
					border-bottom: none;
				}
				&.selected {
					background: #005385;
					border: none;
				}
			}
		}
		.lang_item {
			display: flex;
			align-items: center;
			gap: 16px;
			cursor: pointer;
			justify-content: space-between;
			div {
				display: flex;
				align-items: center;
				gap: 16px;
				span {
					font-size: 16px;
					font-weight: 500;
				}
			}
			span {
				font-size: 16px;
				font-weight: 500;
			}
		}
	`,
);
