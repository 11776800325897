import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PracticeCardContainer = styled.div(
	() => css`
		position: relative;
		top: 153px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 8px;
		padding-bottom: 120px;
		.grouped_colaps {
			position: relative;
			border-radius: 21px;
			padding: 1.5px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%), #3993cb;
			.collection {
				display: grid;
				grid-template-columns: 1fr auto;
				padding: 15px 24px;
				gap: 13px;
				background: #27649a;
				border-radius: 19.5px;
				align-items: center;
				font-size: 16px;
				font-weight: 600;
				line-height: 14px;

				img {
					width: 50px;
					height: 50px;
				}
			}
		}
		.grouped {
			position: relative;
			border-radius: 21px;
			padding: 1.5px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%), #3993cb;

			.collection {
				display: grid;
				grid-template-columns: 1fr;
				padding: 19px 9px 40px;
				gap: 13px;
				background: #3993cb;
				border-radius: 19.5px;
				.border {
					background: radial-gradient(42.79% 40.7% at 50.45% 40.31%, #2899de 0%, #0873b4 90.5%, #0873b4 100%);
					border: 1.5px solid #ffffff;
					box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
					border-radius: 14px;
					display: grid;
					grid-template-columns: 80px 1fr;
					padding: 18px 29px 22px 20px;
					position: relative;
					.lock_caption {
						position: absolute;
						padding: 15px;
						top: 2px;
						left: 0;
						width: 99%;
						right: 0;
						margin: auto;
						text-align: center;
						border-radius: 14px;
						background: radial-gradient(42.79% 40.7% at 50.45% 40.31%, #73caff8c 13%, #0873b482 90.5%, #0873b4b0 100%);
						z-index: 1;
						img {
							width: 64px;
							height: 64px;
						}
					}
					.bought {
						position: absolute;
						padding: 15px;
						top: 2px;
						left: 0;
						width: 99%;
						right: 0;
						margin: auto;
						text-align: center;
						border-radius: 14px;
						z-index: 1;
						background: radial-gradient(42.79% 40.7% at 50.45% 40.31%, #73caff8c 13%, #0873b482 90.5%, #0873b4b0 100%);
						img {
							width: 64px;
							height: 64px;
						}
					}
					.content {
						display: flex;
						flex-direction: column;
						gap: 10px;
						justify-content: center;
						img {
							width: 11px;
						}
						p {
							font-size: 12px;
							font-weight: 500;
							line-height: 17px;
						}
						.cost {
							font-size: 11px;
							font-weight: 500;
							line-height: 15px;
							text-align: center;
							display: flex;
							align-items: center;
							gap: 2px;
							img {
								margin-bottom: 2px;
								width: 11px;
							}
						}
						div {
							font-size: 12px;
							font-weight: 600;
							line-height: 14px;
							text-align: center;
							display: flex;
							justify-content: space-between;
							span {
								color: #ffec2f;
							}
						}
					}
				}
			}
		}
		.container_timer {
			width: 70px;
			height: 70px;
			position: relative;
			font-size: 10px;
			font-weight: 500;
			line-height: 12.38px;
			letter-spacing: 0.04em;

			.clock {
				width: 70px;
				height: 77px;
				margin-bottom: 4px;
			}
		}
	`,
);
