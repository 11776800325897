import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HeaderContainer = styled.header(
	({ theme }) => css`
		position: relative;
		border: none;
		border-radius: 21px;
		background: #3993cb;
		position: relative;
		padding: 17px 19px;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 22px;
			border: 1.5px solid transparent;
			background: linear-gradient(60.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%) border-box;
			-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
			-webkit-mask-composite: destination-out;
			mask-composite: exclude;
		}
		z-index: 2;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		margin-bottom: 16px;

		h1 {
			font-size: 22px;
			font-weight: 600;
			line-height: 27.24px;
			text-align: center;
		}

		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0.01em;
			text-align: center;
		}
	`,
);
