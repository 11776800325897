import { useEffect, useState } from 'react';
import { ShopPranaContainer } from './css';
import { Tabs, Cards, PracticeCard } from './components';
import numberUtils from 'shared/utils/number';
import { useAppSelector } from 'shared/hooks/redux-helper';
import Loader from 'shared/components/loader';
import { GETWeeklyPractice, IGetWeeklyPractice, GETPranaMarketplace, IGetPranaMarketplace } from 'api/v1/prana-game';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changePranaProit } from 'store/reducers/prana';

const PranaShop = () => {
	const [selectedTab, setSelectedTabs] = useState<'Character' | 'Finance' | 'Practice'>('Character');
	const [selectedTabData, setSelectedTabData] = useState<IGetPranaMarketplace['categories']>();
	const [refresh, setRefresh] = useState(0);
	const [data, setData] = useState<IGetPranaMarketplace | null>(null);
	const [practiceData, setPracticeData] = useState<IGetWeeklyPractice>();
	const dispatch = useDispatch();
	const profit = useAppSelector(state => state.prana.profit);
	const balance = useAppSelector(state => state.profile.balance);
	const navigate = useNavigate();
	useEffect(() => {
		setData(null);
		GETPranaMarketplace()
			.then(res => {
				setData(res);
				dispatch(changePranaProit(res.info));
			})
			.catch(e => {
				console.log(e);
			});

		GETWeeklyPractice().then(res => {
			setPracticeData(res);
		});
	}, [refresh]);
	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/games');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);

	useEffect(() => {
		const selectedData = data?.categories.find(category => category.title === selectedTab);
		setSelectedTabData(selectedData ? [selectedData] : undefined);
	}, [selectedTab, data, practiceData]);

	return (
		<ShopPranaContainer>
			<Helmet>
				<title>PinEye | Prana Shop</title>
			</Helmet>
			<div className='bottom_light' />
			<img src='/img/game/banner-prana.webp' alt='' className='banner' />
			<div className='header'>
				<div className='coins_container'>
					<div className='coin'>
						<img src='/img/dollar.webp' alt='' />
						<p>{numberUtils.formatPriceWithDecimals(balance)}</p>
					</div>
					<div className='coin'>
						<img src='/img/profit.webp' alt='' />
						<p>{numberUtils.formatPriceWithDecimals(profit)}</p>
					</div>
				</div>
				<div className='how'>
					<a href='https://docs.pineye.io/prana' id='prana_how_it_work' target='_blank' rel='noreferrer'>
						How it works?
					</a>
				</div>
			</div>

			<Tabs setSelectedTabs={setSelectedTabs} />
			{data === null && <Loader />}
			{selectedTabData && selectedTab !== 'Practice' && (
				<Cards key={'cards-shop'} data={selectedTabData} selectedTab={selectedTab} refresh={refresh} setRefresh={setRefresh} />
			)}
			{selectedTab === 'Practice' && practiceData && (
				<PracticeCard data={practiceData.practices} refresh={refresh} setRefresh={setRefresh} />
			)}
		</ShopPranaContainer>
	);
};

export default PranaShop;
