import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ShopContainer = styled.div(
	() => css`
		color: #fff;
		position: relative;
		background: linear-gradient(180deg, rgba(56, 126, 170, 1) 0%, rgba(141, 189, 220, 1) 20%, rgba(53, 124, 168, 1) 100%);
		padding: 19px;
		height: inherit;

		.banner {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
		}
		.header {
			position: relative;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			.how {
				position: absolute;
				right: 0;
				left: 0;
				top: 114px;
				margin: auto;
				background: linear-gradient(274.5deg, #03dcce 11.65%, #3893cb 57.17%);
				box-shadow: 0px 4px 4px 0px #ffffffa1 inset;
				font-size: 16px;
				font-weight: 500;
				line-height: 20px;
				padding: 12px 22px 8px;
				border-radius: 86px;
				width: 144px;
				color: #fff;
				z-index: 2;
				a {
					text-decoration: none;
					color: #fff;
				}
			}
			.coins_container {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-radius: 18px;
				background: #126090;
				height: 37px;
				justify-self: end;
				border: 1.5px solid transparent;
				border-radius: 18px;
				background-image: linear-gradient(#126090, #126090),
					linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
				background-origin: border-box;
				background-clip: content-box, border-box;
				p {
					font-size: 15px;
					font-weight: 500;
					line-height: 18.57px;
					text-align: center;
					padding: 10px;
				}
				.coin {
					width: 41px;
					height: 41px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #0d7dc2;
					border-radius: 100%;
					border: 2px solid transparent;
					background-origin: border-box;
					background-clip: content-box, border-box;
					border: 2px solid transparent;
					background-image: linear-gradient(#0d7dc2, #0d7dc2),
						linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
					background-origin: border-box;
					background-clip: content-box, border-box;
					img {
						width: 24px;
						height: 24px;
					}
				}
			}
		}
		.light {
			position: absolute;
			top: 130px;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 50px;
			background: #8cbddb;
			filter: blur(10px);
		}
		@media (max-width: 344px) {
			padding: 19px 12px;
			.header {
				.how {
					top: 92px;
				}
			}
			.light {
				top: 100px;
			}
		}
	`,
);
