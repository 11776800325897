import { envResolver } from 'shared/configs/env-resolver';

/* eslint-disable @typescript-eslint/no-explicit-any */
const Telegram = () => {
	function queryStringToJSON(qs: string) {
		const pairs = qs.split('&');
		const result: { [key: string]: any } = {};
		pairs.forEach(function (p) {
			const pair = p.split('=');
			const key = pair[0];
			const value = decodeURIComponent(pair[1] || '');

			if (result[key]) {
				if (Object.prototype.toString.call(result[key]) === '[object Array]') {
					result[key].push(value);
				} else {
					result[key] = [result[key], value];
				}
			} else {
				result[key] = value;
			}
		});

		return JSON.parse(JSON.stringify(result));
	}

	function getTelegramData() {
		let initData = window.Telegram.WebApp.initData;
		let username = '';
		let userId = 0;
		let photo_url: string | null = null;
		if (initData !== '') {
			try {
				const data = queryStringToJSON(initData);
				const user = JSON.parse(data.user);
				username = user?.first_name ?? user.username;
				userId = JSON.parse(data.user).id;
				photo_url = window.Telegram.WebApp.initDataUnsafe.user?.photo_url ?? null;
			} catch (ex) {
				// ?????
			}
		} else {
			if (envResolver.MODE === 'STAGE') {
				username = 'Max';
				userId = 5365969509;
				initData = import.meta.env.VITE_INIT_USER;
			} else {
				throw new Error('Cant Login properly!');
			}
		}

		return { userId, username, photo_url, initData };
	}

	return { getTelegramData };
};

export default Telegram;
