import requestHandler from 'shared/utils/request-handler';
import { IGetCardsResponse } from './card-game.dto';

const BASE_URL = '/api/v2/Game';

export const GETCards = async () => {
	return requestHandler.call<IGetCardsResponse>({ url: `${BASE_URL}/GetCards`, method: 'get' }).then(res => res.data);
};

export const POSTSendAnswer = async (setId: number, answerNo: number) => {
	return requestHandler
		.call<string>({ url: `${BASE_URL}/SendAnswer?setId=${setId}&answerNo=${answerNo}`, method: 'post' })
		.then(res => res.data);
};
