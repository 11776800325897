import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardsContainer = styled.div(
	() => css`
		position: relative;
		top: 153px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;
		padding-bottom: 100px;
		.collection_border {
			position: relative;
			border-radius: 21px;
			padding: 1.5px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%), #3993cb;
			.collection {
				display: grid;
				grid-template-columns: 1fr 1fr;
				padding: 19px 9px 40px;
				gap: 13px;
				background: #3993cb;
				border-radius: 19.5px;
				.box_title {
					grid-column: 1 / 3;
					justify-self: left;
					display: flex;
					width: 100%;
					font-size: 16px;
					font-weight: 700;
					line-height: 14px;
					text-align: left;
					padding-left: 8px;
				}
			}
			.collection_done {
				display: grid;
				grid-template-columns: 1fr 1fr;
				padding: 19px 9px 20px;
				gap: 13px;
				background: #29689e;
				border-radius: 19.5px;
				.title_done {
					grid-column: 1 / 3;
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;
					.box_title {
						font-size: 16px;
						font-weight: 700;
						line-height: 14px;
						text-align: left;
						padding-left: 8px;
						margin-top: 6px;
					}
					.tick {
						width: 30px;
						height: 30px;
						border-radius: 100%;
					}
					.background_tick {
 						position: absolute;
						top: 10%;
						left: 0;
						right: 0;
						background-color: #2271a1bd;
						width:97%;
						border-radius:0 0 20px;
						margin: auto;
						z-index: 1;
						height:85%;
						.tick_big {
 							position: absolute;
							top: 120px;
							left: 0;
							right: 0;
							margin: auto;
						}
					}
				}
			}
		}

		@media (max-width: 344px) {
			top: 120px;
		}
	`,
);
