import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SocialModalContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
		position: relative;
		padding: 22px;
		.loader {
			width: 24px;
			margin-left: 4px;

			animation: rotate 2s linear infinite;
		}

		@keyframes rotate {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}

		.green {
			color: #35d899;
			box-shadow: 5px -2px 4px 0px #0000005e;
			border-radius: 100%;
			margin-left: 4px;
		}
		.header_pic {
			width: 149px;
			height: 149px;
			position: absolute;
			top: -154px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
		}

		.reward_container {
			padding: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 6px;

			.dollar_img {
				width: 48px;
				margin-left: 4px;
			}

			.value {
				color: ${theme.colors.text.primary};
				font-size: 30px;
				font-weight: 500;
				line-height: 37px;
			}
		}

		.btn {
			width: 100%;
		}
		.btn_join {
			min-width: 130px;
		}
	`,
);
