import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MinerContainer = styled.div(
	() => css`
		background: linear-gradient(180deg, #2775a6 0%, rgba(127, 178, 211, 1) 36%, #3188ca 70%);
		color: #fff;
		position: relative;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100vh;
		}
		.table {
			position: absolute;
			top: 40vh;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
		}
		.case {
			position: absolute;
			top: calc(40vh - 100px);
			z-index: 2;
			margin: auto;

			a {
				position: absolute;
				top: 0;
				width: 60vw;
				left: 20vw;
			}
			img {
				width: 100%;
				/* width: 60%; */
			}
		}
		.header {
			position: relative;
			padding: 20px;

			align-items: center;
			display: grid;
			grid-template-columns: 1fr 2px 1.8fr 2px 1fr;
			grid-template-rows: 1.5fr;
			grid-column-gap: 0px;
			grid-row-gap: 0px;
			align-items: flex-end;
			._container {
				display: flex;
				justify-content: center;
				.tempeture,
				.power {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					font-size: 11px;
					font-weight: 400;
					line-height: 13.34px;
					span {
						line-height: 15px;
						font-size: 13px;
					}
					.icon {
						width: 43px;
						height: 43px;
					}
				}
				.profit {
					display: flex;
					justify-content: space-around;
					flex-direction: row;
					align-items: center;
					div {
						margin: 0 2px;
						span {
							font-size: 10.4px;
							font-weight: 400;
							line-height: 12.62px;
							margin-bottom: 4px;
						}
						p {
							font-size: 14px;
							font-weight: 500;
							line-height: 16.98px;
						}
					}
					.icon {
						width: 60px;
						height: 60px;
					}
				}
			}
			.divider {
				width: 1.5px;
				height: 39px;
				background-color: #ffffff36;
				margin: 20px 0 0;
			}
		}

		.footer {
			position: relative;
			padding-bottom: 120px;
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			grid-column-gap: 0px;
			grid-row-gap: 0px;
			justify-items: center;
			.btn {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 70%;
				padding: 4px 14px;
				._container {
					display: flex;
					justify-content: space-around;
					align-items: center;
					img {
						width: 48px;
						height: 48px;
						margin-right: 14px;
					}
					.divider {
						width: 1.5px;
						height: 39px;
						background-color: #0e496d80;
					}
				}
				p {
					text-align: center;
					width: 100%;
					font-size: 20px;
					font-weight: 600;
					line-height: 24.76px;
					letter-spacing: 1px;
				}
			}
		}
		@media (max-height: 665px) {
			.header {
				._container {
					.profit {
						div {
							margin: 0;
						}
					}
				}
			}
			.case {
				top: calc(40vh - 80px);
				a {
					width: 55vw;
				}
			}
		}
	`,
);
