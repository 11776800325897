import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GamesContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		display: grid;
		grid-template-columns: 67px 67px 67px ;
		grid-template-rows: 60px;
		justify-items: center;
		justify-content: center;
        padding-top: 15px;
		gap: 7px;
		margin-bottom: 8px;
		.border {
			padding: 1.5px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			border-radius: 14px;
			position: relative;
			width: 64px;
			.main {
				background: linear-gradient(180deg, #1089d3 50.5%, #056eae 100%);
				border-radius: 12.5px;
				/* width: 62.5px; */
				position: relative;
                text-align: center;
				img {
 					height: 46px;
					top: -16px;
					position: relative;
					left: 0;
					right: 0;
					margin: auto;
 					/* position: absolute;
                   
                    bottom: 67px; */
				}
				p {
					font-size: 12px;
					font-weight: 500;
					line-height: 12.38px;
					text-align: center;
					top: -10px;
					position: relative;
				}
			}
		}
	`,
);
