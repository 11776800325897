import { useRef, useState, useEffect } from 'react';
import numberUtils from 'shared/utils/number';
import { CardGameContainer } from './css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import { EffectCards, Navigation } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper/types';
import { ArrowRightOutline } from 'shared/components/icons';
import Button from 'shared/components/button';
import AnswerModal from './components/answer-modal';
import { GETCards, IGetCardsResponse } from 'api/v2/game';
import Timer from './components/timer';
import Loader from 'shared/components/loader';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const CardGame = () => {
	const swiperRef = useRef<SwiperType | null>(null);
	const [answerModalStatus, setAnswerModalStatus] = useState(false);
	const [data, setData] = useState<IGetCardsResponse | null>(null);
	const [cards, setCards] = useState<IGetCardsResponse['set']['cards']['choice1' | 'choice2' | 'choice3'][]>([]);
	const [activeCard, setActiveCard] = useState<IGetCardsResponse['set']['cards']['choice1' | 'choice2' | 'choice3'] | null>(null);
	const [hasAnswer, setHasAnswer] = useState(false);
	const [answerChoiced, setAnswerChoiced] = useState<number>(-1);
	const [timer, setTimer] = useState(0);
	const [isLoadingAssets, setIsLoadingAssets] = useState(true);
	const navigate = useNavigate();

	const preloadImages = async (urls: string[]) => {
		const promises = urls.map(
			url =>
				new Promise((resolve, reject) => {
					const img = new Image();
					img.src = url;
					img.onload = resolve;
					img.onerror = reject;
				}),
		);
		await Promise.all(promises);
	};

	useEffect(() => {
		GETCards()
			.then(async res => {
				setData(res);
				setHasAnswer(res.userAnswer.hasAnswered);
				setAnswerChoiced(res.userAnswer.answerChoiced);
				setTimer(res.userAnswer.resultTimeRemainSeconds);
				setCards([res.set.cards.choice1, res.set.cards.choice2, res.set.cards.choice3]);
				setActiveCard(res.set.cards.choice1);

				const imageUrls = [
					res.level.backImageUrl,
					'/img/game/q.webp',
					'/img/dollar.webp',
					...(res.set.cards.choice1 ? [res.set.cards.choice1.imageUrl] : []),
					...(res.set.cards.choice2 ? [res.set.cards.choice2.imageUrl] : []),
					...(res.set.cards.choice3 ? [res.set.cards.choice3.imageUrl] : []),
					'/img/game-card/yes.webp',
				];
				await preloadImages(imageUrls);
				setIsLoadingAssets(false);
			})
			.catch(err => {
				console.log(err);
			});
	}, []);

	const onNextClickHandler = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};

	const onPrevClickHandler = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	};

	const handleSlideChange = (swiper: SwiperType) => {
		const currentSlide = cards[swiper.activeIndex];
		setActiveCard(currentSlide);
	};

	const onCloseModal = (state: boolean) => {
		setAnswerModalStatus(state);
	};

	return (
		<CardGameContainer>
			<Helmet>
				<title>PinEye | Card Game</title>
			</Helmet>
			{isLoadingAssets ? (
				<Loader />
			) : data ? (
				<>
					<img src={data.level.backImageUrl} alt='' className='background' />
					<h1>{data.level.title}</h1>
					<p>{data.level.caption}</p>
					<div className='box'>
						<img src='/img/game/q.webp' alt='' className='q' />
						<h3>{data.set.question}</h3>
						<div className='line' />
						<h4>
							Reward: <img src='/img/dollar.webp' alt='' /> {numberUtils.formatPriceWithDecimals(data.set.reward)}
						</h4>
					</div>
					<div className='swiper_container'>
						<Swiper
							onSlideChange={handleSlideChange}
							navigation={{ nextEl: 'next', prevEl: 'prev' }}
							onSwiper={swiper => {
								swiperRef.current = swiper;
							}}
							effect={'cards'}
							grabCursor={true}
							modules={[EffectCards, Navigation]}
							className='mySwiper'
						>
							{cards.map(card => (
								<SwiperSlide key={card.title}>
									<img src={card.imageUrl} alt={card.title} className='img' />
									{card.no === answerChoiced && <img src='/img/game-card/yes.webp' alt='' className='yes' />}
									{card.title}
								</SwiperSlide>
							))}
						</Swiper>
						<div className='prev' onClick={onPrevClickHandler}>
							<ArrowRightOutline />
						</div>
						<div className='next' onClick={onNextClickHandler}>
							<ArrowRightOutline />
						</div>
					</div>

					<div className='button_container'>
						{hasAnswer ? (
							<Button variant='primary' className='lets_go_timer'>
								<Timer date={timer * 1000} />
							</Button>
						) : (
							<Button
								variant='primary'
								isLoading={answerModalStatus}
								className='lets_go'
								onClick={() => setAnswerModalStatus(true)}
								id='card_game_lets_go'
							>
								Let's Go
							</Button>
						)}
						<Button variant='primary' className='history' onClick={() => navigate('/card-game/history')}>
							<p>Card History</p>
						</Button>
					</div>
				</>
			) : null}
			{answerModalStatus && data && activeCard && (
				<AnswerModal
					modalCaption={data.level.modalCaption}
					activeCard={activeCard}
					setId={data.set.id}
					status={answerModalStatus}
					setStatus={onCloseModal}
				/>
			)}
		</CardGameContainer>
	);
};
export default CardGame;
