import { FC } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { ProfitContainerModal } from '../css';
import { useNavigate } from 'react-router-dom';

const ProfitModal: FC<{ status: boolean; setStatus: (status: boolean) => void; profit: number }> = ({ status, setStatus, profit }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	const onCloseClickHandler = () => {
		onClose();
	};
	return (
		<Modal status={_status} onClose={onCloseClickHandler} title='' isBottomSheet>
			<ProfitContainerModal>
				<img src='img/prana/profit-boda.webp' alt='' className='header_img' />
				<p className='title'>
					<img src='/img/dollar.webp' /> +{profit}
				</p>
				<p className='caption'>
					Great news!
					<br />
					You’ve {profit} received coins from your Prana's profit.
				</p>

				<Button
					variant='primary'
					onClick={() => {
						onClose();
						navigate('/');
					}}
				>
					Thanks!
				</Button>
			</ProfitContainerModal>
		</Modal>
	);
};

export default ProfitModal;
