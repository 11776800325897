import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { OverviewContainer } from '../css';
import { GetExchange } from 'api/v1/exchange';
import DotLoader from 'shared/components/dot-loader';
import { useDispatch } from 'react-redux';
import { GETPranaGameInfo } from 'api/v1/prana-game';
import { changePranaLevel, changePranaProit } from 'store/reducers/prana';
import numberUtils from 'shared/utils/number';
import InfoModal from 'pages/games/components/info-modal';
const Overview = () => {
	const profile = useAppSelector(state => state.profile);
	const profit = useAppSelector(state => state.prana.profit);
	const [statusModal, setStatusModal] = useState(false);
	const [type, setType] = useState('');

	const [exchangeName, setExchangeName] = useState<string | null>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		GetExchange().then(res => {
			setExchangeName(res);
		});
		GETPranaGameInfo().then(res => {
			dispatch(changePranaProit(res));
			dispatch(changePranaLevel(res));
		});
	}, []);

	const getExchangeImage = (exchangeName: string) => {
		switch (exchangeName.toLowerCase()) {
			case 'bybit':
				return <img src='/img/pineye/bybit.webp' className='pic ' alt=' ' />;
			case 'binance':
				return <img src='/img/pineye/binance.webp' className='pic ' alt=' ' />;
			case 'kucoin':
				return <img src='/img/pineye/kucoin.webp' className='pic ' alt=' ' />;
			case 'okx':
				return <img src='/img/pineye/okx.webp' className='pic ' alt=' ' />;
			default:
				return null;
		}
	};

	/*const openModal = () => {
		if (profit < 700) {
			setMinerModalStatus(true);
		} else {
			navigate('/games/miner');
		}
	};*/

	return (
		<OverviewContainer>
			{/* <div className='background'>
				<img src='/img/pineye/Subtract.webp' alt='' />
			</div> */}
			<Link to={'/leagues'} className='card_item_container  '>
				<div className='card_item'>
					<div className='pic right '>
						<img src='/img/pineye/new_profile.webp' alt='pin' width={29} height={29} />
					</div>
					<div className='text_container'>
						<div className='value'>Level {profile.level}</div>
					</div>
				</div>
			</Link>
			<div className='divider' />
			<Link to='/exchange' className='card_item_container   '>
				<div className='card_item'>
					<div className='pic  '>
						{exchangeName === null || exchangeName === '' ? (
							<img src='/img/icon/exchange.webp' alt='pin' width={29} height={29} />
						) : (
							getExchangeImage(exchangeName)
						)}
					</div>
					<div className='text_container'>
						<div className='value'>{exchangeName === null ? <DotLoader /> : exchangeName === '' ? 'Choose' : exchangeName}</div>
					</div>
				</div>
			</Link>
			<div className='divider' />
			<div
				className='card_item_container'
				onClick={() => {
					setStatusModal(true);
					setType('Profit');
				}}
			>
				<div className='card_item'>
					<div className='pic  '>
						<img src='/img/profit.webp' alt='pin' width={29} height={29} />
					</div>
					<div className='text_container'>
						<div className='value'>
							Profit: <span>{numberUtils.formatPriceWithDecimals(profit)}</span>
						</div>
					</div>
				</div>
			</div>
			{statusModal && type.length > 0 && (
				<InfoModal setStatus={setStatusModal} setType={() => setType('')} type={type} status={statusModal} />
			)}
		</OverviewContainer>
	);
};
export default Overview;
