import { FC } from 'react';
import { TimerContainer } from '../css';
import { TimerVector } from 'pages/lottery/img';
import { useCountDownHours } from 'shared/hooks/use-count-down-hours';

const Timer: FC<{ date: number }> = ({ date }) => {
	const { hours, minutes, seconds } = useCountDownHours(date + Date.now() - new Date().getTime());
	return (
		<TimerContainer>
			<div className='timer_scope'>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{hours.toString()[0]}</div>
				</div>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{hours.toString()[1]}</div>
				</div>
			</div>
			<div className='colon'>:</div>
			<div className='timer_scope'>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{minutes.toString()[0]}</div>
				</div>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{minutes.toString()[1]}</div>
				</div>
			</div>
			<div className='colon'>:</div>
			<div className='timer_scope'>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{seconds.toString()[0]}</div>
				</div>
				<div className='timer_item'>
					<TimerVector />
					<div className='time'>{seconds.toString()[1]}</div>
				</div>
			</div>
		</TimerContainer>
	);
};

export default Timer;
