import { FC, useState } from 'react';
import { CardsContainer } from '../css';
import BuyModal from './buy-modal';
import Card from './single-card';
import { IGetPranaMarketplace } from 'api/v1/prana-game';
interface ICardsProps {
	data: IGetPranaMarketplace['categories'];
	selectedTab: string;
	setRefresh: (refresh: number) => void;
	refresh: number;
}

const Cards: FC<ICardsProps> = ({ data, setRefresh, refresh }) => {
	const [buyModalStatus, setBuyModalStatus] = useState<{ status: boolean }>({ status: false });

	const [selectedId, setSelectedId] = useState<{ cardId: number; collectionId: number; categoryId: number; level: number } | null>(null);
	const [expanded, setExpanded] = useState<boolean>(false);
	return (
		<CardsContainer>
			{data.map((item, index) =>
				item.collections
					.filter(i => i.status === 'doing')
					.map(colec => (
						<div className='collection_border' key={`${colec.id}-${index}-card`}>
							<div className='collection'>
								<p className='box_title'>
									{colec.title.charAt(0).toUpperCase() + colec.title.slice(1)}-Part {colec.level}
								</p>
								{colec.cards.map((card, i) => (
									<Card
										key={`card-${card.id}-${i}`}
										item={card}
										select={() => {
											setSelectedId({
												cardId: card.id,
												collectionId: colec.id,
												categoryId: item.id,
												level: colec.cards.map(c => c.currentLevel)[i],
											});
										}}
										buy={() => setBuyModalStatus({ status: true })}
										tick={true}
									/>
								))}
							</div>
						</div>
					)),
			)}
			{data.map((item, index) =>
				item.collections
					.filter(i => i.status === 'todo')
					.map(colec => (
						<div className='collection_border' key={`${colec.id}-${index}-todo-card`}>
							<div className='collection'>
								<p className='box_title'>
									{colec.title.charAt(0).toUpperCase() + colec.title.slice(1)}-Part {colec.level}
								</p>

								{colec.cards.map((card, index) => (
									<Card
										key={`card-${card.id}-${index}`}
										item={card}
										select={() => {
											console.log('first');
										}}
										buy={() => {
											setBuyModalStatus({ status: false });
										}}
										lock={true}
									/>
								))}
							</div>
						</div>
					)),
			)}
			{data.map((item, index) =>
				item.collections
					.filter(i => i.status === 'done')
					.map(i => ({ ...i, expanded: false }))
					.map(colec => (
						<div className='collection_border' key={`${colec.id}-${index}-done-card`}>
							<div className='collection_done' onClick={() => setExpanded(!colec.expanded ? !expanded : expanded)}>
								<div className='title_done' key={`${colec.id}-${index}`}>
									<p className='box_title'>
										{colec.title.charAt(0).toUpperCase() + colec.title.slice(1)}-Part {colec.level}
									</p>
									<div className={expanded ? 'background_tick' : ''}>
										<img src='/img/game/bought.webp' className={expanded ? 'tick_big' : 'tick'} />
									</div>
								</div>
								{expanded && (
									<>
										{colec.cards.map((card, index) => (
											<Card
												key={`card-${card.id}-${index}`}
												item={card}
												select={() => {
													console.log('first');
												}}
												buy={() => {
													setBuyModalStatus({ status: false });
												}}
												tick={false}
											/>
										))}
									</>
								)}
							</div>
						</div>
					)),
			)}
			{buyModalStatus.status && (
				<BuyModal
					setRefresh={() => setRefresh(refresh + 1)}
					status={buyModalStatus.status}
					setStatus={status => setBuyModalStatus({ status })}
					data={data}
					id={selectedId}
				/>
			)}
		</CardsContainer>
	);
};

export default Cards;
