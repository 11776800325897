import { useEffect, useState } from 'react';
import Icon from 'shared/components/icon';
import numberUtils from 'shared/utils/number';
import { SingleCardContainer } from '../css';
// import { useAppSelector } from 'shared/hooks/redux-helper';
import { CircleProgress } from 'shared/components/progress-bar';
import { ICards } from 'api/v1/prana-game';

const formatTimer = (countDown: number) => {
	let days: number | string = Math.floor(countDown / (60 * 60 * 24));
	let hours: number | string = Math.floor((countDown % (60 * 60 * 24)) / (60 * 60));
	let minutes: number | string = Math.floor((countDown % (60 * 60)) / 60);
	let seconds: number | string = Math.floor(countDown % 60);

	if (days < 0) {
		days = 0;
	}
	if (hours < 0) {
		hours = 0;
	}
	if (minutes < 0) {
		minutes = 0;
	}
	if (seconds < 0) {
		seconds = 0;
	}

	if (days <= 9) {
		days = `0${days}`;
	}
	if (hours <= 9) {
		hours = `0${hours}`;
	}
	if (minutes <= 9) {
		minutes = `0${minutes}`;
	}
	if (seconds <= 9) {
		seconds = `0${seconds}`;
	}

	return { days, hours, minutes, seconds };
};

const renderTimer = ({ hours, minutes, seconds }: Record<'hours' | 'minutes' | 'seconds', string | number>) => {
	return +hours > 0
		? `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
		: `${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
};

const Card = ({ item, select, buy, lock, tick }: { tick?: boolean; item: ICards; select: () => void; buy: () => void; lock?: boolean }) => {
	const timeStamp = Math.floor((item.cooldownEndTimestamp - Date.now()) / 1000);

	const [timer, setTimer] = useState<number>(timeStamp);
	const percent = (timer * 100) / item.totalCooldownTime;

	useEffect(() => {
		if (item.totalCooldownTime > 0) {
			const interval = setInterval(() => {
				setTimer(prevTimer => prevTimer - 1);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, []);
	return (
		<SingleCardContainer
			onClick={() => {
				if (timeStamp > 0) {
					return;
				}
				if (item.isCompleted) {
					return;
				}
				buy();
				select();
			}}
		>
			{lock && (
				<>
					<div className='lock_caption'>
						<img src='/img/shop/lock.webp' />
					</div>

					<div className='lock' />
				</>
			)}

			{tick && item.isCompleted && (
				<>
					<img src='/img/shop/bought.webp' className='tick' />
					<div className='bought' />
				</>
			)}

			{timer && timer > 0 && !item.isCompleted ? (
				<div className='container_timer'>
					<div className='background' />
					<CircleProgress
						width={80}
						percentage={percent}
						strokeWidth={5}
						primaryColor={['#0EEAE0', '#5A71F0']}
						secondaryColor='#003457'
						children={
							<>
								<Icon name='clock' className='clock' />
								<span>{renderTimer(formatTimer(timer))}</span>
							</>
						}
					/>
				</div>
			) : (
				<div className='item_img'>
					<img src={item.imageUrl} alt='' />
				</div>
			)}
			<p className='title'>{item.title}</p>

			<p className='profit'>
				Profit: <span>+{item.profit} </span>
			</p>

			<div className='value'>
				<div className='border'>
					<div className='level'>lvl {item.currentLevel}</div> Cost: {<img src='/img/dollar.webp' alt='' />}
					{numberUtils.formatPriceWithDecimals(item.cost)}
				</div>
			</div>
		</SingleCardContainer>
	);
};

export default Card;
