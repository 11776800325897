import { SplashContainer } from './splash.style';
import { useEffect, useState } from 'react';

const Splash = () => {
	const [width, setWidth] = useState('0%');

	useEffect(() => {
		const interval = setInterval(() => {
			if (width === '99%') {
				clearInterval(interval);
			} else {
				setWidth(prev => {
					const newWidth = +prev.slice(0, -1) + 1;
					return `${Math.min(newWidth, 99)}%`;
				});
			}
		}, 10);
	}, []);

	return (
		<SplashContainer>
			<p>{width}</p>
			<div className='progress_container '>
				<div className='border'>
					<div
						className='filled'
						style={{
							width: width,
						}}
					></div>
				</div>
			</div>
		</SplashContainer>
	);
};

export default Splash;
