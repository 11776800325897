import { FC, useState } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { AnswerModalContainer } from '../css';
import { IGetCardsResponse } from 'api/v1/card-game';
import Button from 'shared/components/button';
import { POSTSendAnswer } from 'api/v1/card-game';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AnswerModal: FC<{
	activeCard: IGetCardsResponse['set']['cards']['choice1' | 'choice2' | 'choice3'];
	modalCaption: string;
	status: boolean;
	setId: number;
	setStatus: (status: boolean) => void;
}> = ({ setId, modalCaption, activeCard, status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const onCloseClickHandler = () => {
		onClose();
	};

	const handleChoose = () => {
		setLoading(true);
		POSTSendAnswer(setId, activeCard.no)
			.then(() => {
				toast.success('Card Selected Successfully.');
				onClose();
				navigate('/');
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Modal
			isBottomSheet
			status={_status}
			onClose={onCloseClickHandler}
			title=''
			backgroundColor='linear-gradient(180deg, #0B3079 0%, #3681D0 100%)'
		>
			<AnswerModalContainer>
				<img src={activeCard.imageUrl} alt='' className='header_img' />
				<h1>{activeCard.title}</h1>
				<p dangerouslySetInnerHTML={{ __html: modalCaption }}></p>
				<Button variant='primary' isLoading={loading} onClick={handleChoose}>
					Continue
				</Button>
			</AnswerModalContainer>
		</Modal>
	);
};

export default AnswerModal;
