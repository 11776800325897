import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AnswerModalContainer = styled.div(
	({ theme }) => css`
		position: relative;
		color: ${theme.colors.text.primary};
		padding: 40px 24px;
		height: 270px;
		display: flex;
		flex-direction: column;
		gap:16px;
		.header_img {
			width: 174px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -270px;
		}
		h1 {
			font-size: 24px;
			font-weight: 500;
			line-height: 29.71px;
			text-align: center;
		}
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: center;
		}
		button{
			width: 100%;
		}
	`,
);
