import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InfoModalContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		padding: 0 24px 32px;
		position: relative;
		text-align: center;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		z-index: 5;
	 
		.calender {
			width: 310px;
			height: 310px;
			position: absolute;
			top: -300px;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 5;
		}
		.content {
			text-align: center;
			color: ${theme.colors.text.primary};
			display: flex;
			flex-direction: column;
			gap: 24px;

			h3 {
				font-size: 24px;
				font-weight: 500;
				line-height: 29.71px;
				text-align: center;
			}

			p {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				text-justify: center;
 				justify-self: center;
				/* padding: 0 24px; */
			}

			button {
				width: 100%;
				font-size: 18px;
				font-weight: 500;
				line-height: 22.28px;
			}
		}
		@media (max-height: 667px) {
        padding: 0 24px 10px;
        gap: 16px;
        .calender {
            width:230px;
            height: 230px;
            top: -220px;
        }
        .content{
            height: 200px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;
            h3 {
                padding-top:20px;
            line-height: 20px;
            font-size: 20px;
            }
            p {
            line-height: 20px;
            font-size: 14px:
            }
        
        }
    }
	`,
);
