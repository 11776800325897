import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const EarnContainer = styled.div(
	() => css`
		padding: 24px 24px 120px;
		background: linear-gradient(180deg, #357FAB 15%, rgba(127,178,211,1) 36%, rgba(34,113,161,1) 100%);
		position: relative;
		.top_light{
			background: #56BEFFE0;
			filter: blur(122.69999694824219px);
			position: absolute;
			top: 90px;
			left: 0;
			right: 0;
			height: 200px;
			width: 100%;
			z-index: -1;
		}
	`,
);
