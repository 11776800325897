import { FC } from 'react';
import { IGetPlayersResponse } from 'api/v2/levels';
import { TopRanksContainer } from '../css';
import { Background } from '../img';
import numberUtils from 'shared/utils/number';

interface RankingProps {
	data: IGetPlayersResponse[];
}

const TopRanks: FC<RankingProps> = ({ data }) => {
	const players = data;
	return (
		<TopRanksContainer>
			<Background className='background' />
			<div className='pics'>
				<div className='two'>
					<img src='/img/leagues/two.webp' alt='' />
					<p className='name'>{players[1]?.userName}</p>
					<p className='number'>{numberUtils.formatPriceWithDecimals(players[1]?.balance)}</p>
				</div>
				<div className='one'>
					<img src='/img/leagues/one.webp' alt='' />
					<p className='name'>{players[0]?.userName}</p>
					<p className='number'>{numberUtils.formatPriceWithDecimals(players[0]?.balance)}</p>
				</div>
				<div className='three'>
					<img src='/img/leagues/three.webp' alt='' />
					<p className='name'>{players[2]?.userName}</p>
					<p className='number'>{numberUtils.formatPriceWithDecimals(players[2]?.balance)}</p>
				</div>
			</div>
		</TopRanksContainer>
	);
};

export default TopRanks;
