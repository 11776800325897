import { Helmet } from 'react-helmet';
import { PracticeContainer } from './css';
import { useEffect, useRef, useState } from 'react';
import { GETClaimPractice, IGetWeeklyPractice, GETWeeklyPractice } from 'api/v1/prana-game';
import { InfoModal, Timer, TimerFixed, CongratModal, SelectLangModal } from './components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeBalance, changeReplay } from 'store/reducers/profile';
import { changePranaProit } from 'store/reducers/prana';

const Practice = () => {
	const [practiceData, setPracticeData] = useState<IGetWeeklyPractice['practices'][0]['days'][0]>();
	const [start, setStart] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [end, setEnd] = useState(false);
	const [letStart, setLetStart] = useState(false);
	const [count, setCount] = useState<number | null>(null);
	const [congratModal, setCongratModal] = useState(false);
	const [selctLangModal, setSelctLangModal] = useState(true);
	const [selectedLang, setSelectedLang] = useState<'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian'>('english');
	const [sound, setSound] = useState(true);
	const audioRef = useRef<HTMLAudioElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const id = window.location.pathname.split('/').pop();

	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/games');
		});
		GETWeeklyPractice().then(res => {
			if (id) {
				const filteredPractice = res.practices[1].days.filter(i => i.id === +id);
				if (filteredPractice.length > 0) {
					setPracticeData(filteredPractice[0]);
				}
			}
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);

	useEffect(() => {
		if (sound && start && !end && audioRef.current) {
			(audioRef.current as HTMLAudioElement).play();
		} else if (!sound && audioRef.current) {
			(audioRef.current as HTMLAudioElement).pause();
			(audioRef.current as HTMLAudioElement).currentTime = 0;
		} else if (!start && end && audioRef.current) {
			(audioRef.current as HTMLAudioElement).pause();
			(audioRef.current as HTMLAudioElement).currentTime = 0;
		}
	}, [sound, start, end]);
	useEffect(() => {
		if (start && !end && videoRef.current) {
			videoRef.current.play();
		} else if (videoRef.current) {
			videoRef.current.pause();
		}
	}, [start, sound, end]);

	const handleClose = () => {
		if (end) {
			navigate('/games');
		} else {
			setWarningModal(true);
		}
	};

	useEffect(() => {
		if (letStart) {
			let n = 3;
			const interval = setInterval(() => {
				if (n > 0) {
					setCount(n);
					n--;
				} else {
					clearInterval(interval);
					setLetStart(false);
					setStart(true);
					setCount(null);
				}
			}, 1000);
		}
		return () => {};
	}, [letStart]);
	useEffect(() => {
		if (end) {
			setCongratModal(true);
			GETClaimPractice()
				.then(res => {
					dispatch(changeBalance(res));
					dispatch(changePranaProit(res));
					dispatch(changeReplay());
				})
				.then(() => {
					setEnd(false);
					setStart(false);
				});
		}
	}, [end]);

	return (
		<PracticeContainer>
			<Helmet>
				<title>PinEye | prana Practice</title>
			</Helmet>
			<audio ref={audioRef} src={practiceData?.voices[selectedLang]} loop style={{ display: 'none' }} />

			<img className='background' src='/img/prana/background-2.webp' alt='' />

			{letStart && (
				<div className='counter_container'>
					<div className='lets_start'>{count}</div>
				</div>
			)}

			<div className='man_container'>
				<img className='man' src='/img/prana/man.webp' alt='' />
				<div className='head'>
					<div className='close' onClick={() => handleClose()}>
						<img src={'/img/prana/close.webp'} alt='' />
					</div>
					<div className='sound' onClick={() => setSound(!sound)}>
						{sound ? <img src='/img/game/sound.webp' alt='' /> : <img src='/img/game/sound-off.webp' alt='' />}
					</div>
				</div>

				<video
					ref={videoRef}
					poster='/img/prana/ini.webp'
					className='infinite'
					loop
					playsInline
					muted={true}
					preload='auto'
					style={{ display: 'block' }}
				>
					<source src='/video/infinite.webm' type='video/webm' />
				</video>
			</div>
			<p className='text'>
				Close your Eyes and
				<br />
				Listen to the <strong>Voice</strong>
			</p>
			{practiceData && (
				<div className='timer'>
					{!start && !end && <TimerFixed date={practiceData.practiceTime * 1000} />}
					{start && (
						<Timer
							setEnd={() => {
								setEnd(true);
								setStart(false);
							}}
							date={practiceData.practiceTime * 1000}
						/>
					)}
				</div>
			)}

			{warningModal && <InfoModal status={warningModal} setStatus={setWarningModal} />}
			{congratModal && <CongratModal status={congratModal} setStatus={setCongratModal} />}
			{selctLangModal && (
				<SelectLangModal
					selectedLang={selectedLang}
					setSelectedLang={setSelectedLang}
					status={selctLangModal}
					setStatus={setSelctLangModal}
					handleStart={() => {
						setLetStart(true);
						setTimeout(() => {
							setStart(true);
						}, 4000);
					}}
				/>
			)}
		</PracticeContainer>
	);
};
export default Practice;
