import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardHistoryContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		.background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100%;
		}

		h1 {
			padding-top: 45px;
			font-size: 22px;
			font-weight: 600;
			line-height: 27.24px;
			text-align: center;
			position: relative;
			z-index: 1;
		}
		.levels {
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 15px;
			padding-bottom: 16px;

			h2 {
				font-size: 15px;
				font-weight: 600;
				line-height: 18.57px;
				text-align: center;
			}
			.line {
				position: relative;
				z-index: 1;
				width: 100%;
			}
			.items {
				position: relative;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: 1fr;
				gap: 10px;
				padding: 0 16px;

				.item {
					position: relative;
					width: 100%;

					.card_img {
						width: 100%;
						position: relative;
					}
					.status {
						position: absolute;
						width: 45px;
						top: 9vh;
						right: 0;
						left: 0;
						margin: auto;
					}
					.passed {
						position: absolute;
						width: 70px;
						top: 8vh;
						right: 0;
						left: 0;
						margin: auto;
					}
				}
			}
		}
		.start_btn {
			width: 92% !important;
			position: fixed;
			bottom: 120px;
			z-index: 2;
			left: 0;
			right: 0;
			margin: auto;
		}
	`,
);
