import requestHandler from 'shared/utils/request-handler';
import {
	IGetPranaGameInfo,
	IGetPranaMarketplace,
	IPostPranaPurch,
	IGetPractice,
	IGetClaimPractice,
	IGetWeeklyPractice,
} from './prana-game.dto';

const BASE_URL = '/api/v1/PranaGame';

export const GETPranaGameInfo = async () => {
	return requestHandler.call<IGetPranaGameInfo>({ url: `${BASE_URL}/Info`, method: 'get' }).then(res => res.data);
};

export const GETPranaMarketplace = async () => {
	return requestHandler.call<IGetPranaMarketplace>({ url: `${BASE_URL}/Marketplace`, method: 'get' }).then(res => res.data);
};

export const POSTPranaPurch = async (cardId: number, level: number) => {
	return requestHandler
		.call<IPostPranaPurch>({ url: `${BASE_URL}/Purch?cardId=${cardId}&level=${level}`, method: 'post' })
		.then(res => res.data);
};

export const GETPractice = async () => {
	return requestHandler.call<IGetPractice>({ url: `${BASE_URL}/GetPractice`, method: 'get' }).then(res => res.data);
};

export const GETClaimPractice = async () => {
	return requestHandler.call<IGetClaimPractice>({ url: `${BASE_URL}/ClaimPractice`, method: 'get' }).then(res => res.data);
};

export const GETWeeklyPractice = async () => {
	return requestHandler.call<IGetWeeklyPractice>({ url: `${BASE_URL}/GetWeeklyPractice`, method: 'get' }).then(res => res.data);
};
