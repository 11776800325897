import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ReferralContainer = styled.section(
	({ theme }) => css`
		padding: 1.5px;
		background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
		border-radius: 21px;
		.background {
			background: #3993cb;
			padding: 19px 10px;
			border-radius: 19.5px;
			color: ${theme.colors.text.primary};
			display: flex;
			flex-direction: column;
			gap: 12px;
			.referral_card {
				background: #005385;
				border-radius: 15px;
				padding: 14px 17px 14px;
				display: grid;
				grid-template-columns: 44px 1fr auto;
				grid-template-rows: 1fr;
 				gap:8px;
				.img_container {
					grid-row: 1/3;
					width: 44px;
					height: 44px;
					background: transparent;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: auto;
					img {
						width: 44px;
					}
				}

				.title {
					margin: auto 0;
					text-align: left;
					padding-top: 10px;
					p {
						font-size: 16px;
						font-weight: 500;
						line-height: 19.41px;
						
					}
					.progress {
						font-size: 12px;
						font-weight: 400;
						line-height: 14.56px;
					}
				}
				.value {
					margin: auto;
					display: flex;
					padding-top: 10px;
					align-items: center;
					gap: 7px;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.value_container {
						display: flex;
						align-items: center;
						gap: 5px;
						font-weight: 600;
						font-size: 14px;
						line-height: 17px;
						color: #a1fff9;
						background: #000;
						display: flex;
						padding: 7px 10px 4px;
						border-radius: 6px;
						align-items: flex-start;
						gap: 5px;
						position: relative;

						&.checked{
							padding-right: 15px;
						}
						img {
							width: 14px;
						}
						.green {
							position: absolute;
							right: -12px;
							top: 0;
							width: 25px;
							height: 25px;
							object-fit: contain;
							color: #35d899;
							margin: auto;
						}
					}
					.claim_btn {
						background: linear-gradient(255.79deg, #34d899 28.77%, #0dac6f 62.19%);
						padding: 4px 10px;
						border-radius: 6px;
						width: 100%;
						text-align: center;
						font-weight: 600;
						font-size: 14px;
						line-height: 17px;
						box-shadow: 2px 3px 3px 0px #00000040;
						border: 1px solid #ffffffb8;
					}
				}
			}
		}
		@media (max-width: 360px) {
			.background {
				.referral_card {
					.title {
						p {
							font-size: 12px;
						}
					}
					.value {
						.value_container {
							font-size: 10px;
							padding: 4px 8px;
							.green {
								width: 18px;
								height: 18px;
								right: -10px;
								margin:4px auto;
							}
						}
						.claim_btn {
							font-size: 10px;
							padding: 5px 8px;
						}
					}
				}
			}
		}
	`,
);
