import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SingleCardContainer = styled.div(
	({ theme }) => css`
		background: radial-gradient(42.79% 40.7% at 50.45% 40.31%, #73caff 0%, #0873b4 90.5%, #0873b4 100%);
		border: 1.5px solid #ffffff;
		box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
		border-radius: 27px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px 8px 10px;
		gap: 6px;
		text-align: center;
		position: relative;

		.tick {
			position: absolute;
			z-index: 1;
			width: 42px;
			height: 42px;
		}
		.bought {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100%;
			background: radial-gradient(42.79% 40.7% at 50.45% 40.31%, #73caff69 0%, #0873b4 90.5%, #0873b4cc 100%);
			filter: blur(10px);
			border-radius: 25px;
			border: 2px solid white;
		}
		.lock {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100%;
			background: radial-gradient(42.79% 40.7% at 50.45% 40.31%, #73caff 13%, #0873b4 90.5%, #0873b4 100%);
			filter: blur(25px);
			border-radius: 25px;
			border: 2px solid white;
		}
		.lock_caption {
			z-index: 1;
			justify-content: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			img {
				position: absolute;
				z-index: 1;
				width: 42px;
				height: 42px;
				top: 60px;
			}
			.title {
				font-size: 13.5px;
				font-weight: 700;
				line-height: 14px;
				text-align: center;
				top: 90px;
				position: absolute;
			}
			.power_cap,
			.fan_cap,
			.cpu_cap {
				text-align: center;
				top: 115px;
				position: absolute;
			}
			.value {
				padding: 2px;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.45) -14.29%, rgba(153, 153, 153, 0) 50%);
				border-radius: 27px;
				width: 94%;
				top: 135px;
				position: absolute;
				.border {
					font-size: 12px;
					font-weight: 600;
					line-height: 20px;
					color: ${theme.colors.text.primary};
					display: flex;
					align-items: flex-end;
					justify-content: center;
					background: #0873b4;
					border-radius: 24px;
					height: 30px;
				}
			}
		}
		.item_img {
			width: 68px;
			height: 68px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.container_timer {
			width: 70px;
			height: 70px;
			position: relative;
			font-size: 10px;
			font-weight: 500;
			line-height: 12.38px;
			letter-spacing: 0.04em;

			.clock {
				color: #03dcce;
				width: 20px;
				height: 20px;
				margin-bottom: 4px;
			}
			.background {
				position: absolute;
				top: 0px;
				left: 0;
				right: 0;
				width: 75px;
				height: 75px;
				border-radius: 50%;
				background: #126090;
				filter: blur(8px);
			}
		}
		.title {
			font-size: 13.5px;
			font-weight: 500;
			line-height: 14px;
			text-align: center;
		}

		.profit {
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			text-align: center;
			span {
				color: #ffec2f;
			}
		}

		.value {
			padding: 2px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0.45) -14.29%, rgba(153, 153, 153, 0) 50%);
			border-radius: 27px;
			width: -webkit-fill-available;

			.border {
				font-size: 11px;
				font-weight: 500;
				line-height: 15px;
				color: ${theme.colors.text.primary};
				display: flex;
				align-items: flex-end;
				gap: 3px;
				justify-content: center;

				/* margin: 0 auto auto 0; */
				background: #0873b4;
				border-radius: 24px;
				height: 30px;
				.level {
					font-size: 11px;
					font-weight: 500;
					line-height: 15px;
					text-align: center;
					border-right: 1px solid #59a2cf;
					padding-right: 4px;
				}
				img {
					width: 11px;
					margin-bottom: 3px;
				}
			}
		}
		@media (max-width: 344px) {
			.value {
				.border {
					font-size: 9px;

					.level {
						font-size: 8px;
					}
				}
			}
		}
	`,
);
