import { useEffect, useState } from 'react';
import Icon from 'shared/components/icon';
import numberUtils from 'shared/utils/number';
import { IGetShopResponse, TShopDto } from 'api/v1/miner';
import { SingleCardContainer } from '../css';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { CircleProgress } from 'shared/components/progress-bar';

const formatTimer = (countDown: number) => {
	let days: number | string = Math.floor(countDown / (1000 * 60 * 60 * 24));
	let hours: number | string = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	let minutes: number | string = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	let seconds: number | string = Math.floor((countDown % (1000 * 60)) / 1000);

	if (days < 0) {
		days = 0;
	}
	if (hours < 0) {
		hours = 0;
	}
	if (minutes < 0) {
		minutes = 0;
	}
	if (seconds < 0) {
		seconds = 0;
	}

	if (days <= 9) {
		days = `0${days}`;
	}
	if (hours <= 9) {
		hours = `0${hours}`;
	}
	if (minutes <= 9) {
		minutes = `0${minutes}`;
	}
	if (seconds <= 9) {
		seconds = `0${seconds}`;
	}

	return { days, hours, minutes, seconds };
};

const renderTimer = ({ hours, minutes, seconds }: Record<'hours' | 'minutes' | 'seconds', string | number>) => {
	return +hours > 0
		? `${hours.toString().padStart(2, '0')} : ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
		: `${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
};

const Card = ({
	item,
	select,
	selectedTab,
	buy,
	task,
	canUse,
}: {
	item: IGetShopResponse[TShopDto];
	select: () => void;
	selectedTab: string;
	buy: () => void;
	task: () => void;
	canUse: boolean;
}) => {
	const [timer, setTimer] = useState<number>(item.cooldownTime);
	const percent = (item.cooldownTime * 100) / item.overAllCooldownTime;
	const balance = useAppSelector(state => state.profile.balance);

	useEffect(() => {
		if (item.overAllCooldownTime > 0) {
			const interval = setInterval(() => {
				setTimer(prevTimer => prevTimer - 1);
			}, 1000);

			return () => clearInterval(interval);
		}
	}, []);

	return (
		<SingleCardContainer
			onClick={() => {
				if (item.isBought || !item.canBuy || item.cost > balance || item.cooldownTime > 0) return;
				if (canUse) {
					buy();
				} else {
					task();
				}
				select();
			}}
		>
			{item.isBought && (
				<>
					<img src='/img/shop/bought.webp' className='tick' />
					<div className='bought' />
				</>
			)}
			{!item.isBought && !item.canBuy && (
				<>
					<div className='lock_caption'>
						<img src='/img/shop/lock.webp' />
						<p className='title'>{item.model}</p>
						{(selectedTab === 'Power' || selectedTab === 'Board') && 'wattage' in item && (
							<p className='power_cap'>{item.wattage} Watts</p>
						)}
						{selectedTab === 'Fan' && 'temp' in item && (
							<p className='fan_cap'>
								Cool Miner <span>{item.temp}°</span>
							</p>
						)}
						{(selectedTab === 'CPU' || selectedTab === 'GPU') && 'income' in item && (
							<p className='cpu_cap'>
								Profit: <span>+{item.income} C/Hr</span>
							</p>
						)}
						<div className='value'>
							<div className='border'>
								{/* {getComponentType(item.type)} */}
								Buy {item.requirement}
							</div>
						</div>
					</div>

					<div className='lock' />
				</>
			)}
			{item.cooldownTime && item.cooldownTime > 0 ? (
				<div className='container_timer'>
					<div className='background' />
					<CircleProgress
						width={80}
						percentage={percent}
						strokeWidth={5}
						primaryColor={['#0EEAE0', '#5A71F0']}
						secondaryColor='#003457'
						children={
							<>
								<Icon name='clock' className='clock' />
								<span>{renderTimer(formatTimer(timer))}</span>
							</>
						}
					/>
				</div>
			) : (
				<div className='item_img'>
					<img src={item.imageUrl} alt='' />
				</div>
			)}
			<p className='title'>{item.model}</p>
			{(item.type === 0 || item.type === 2) && <p className='caption'>Purchase to Upgrade</p>}
			{selectedTab === 'Power' && 'wattage' in item && <p className='power_cap'>{item.wattage} Watts</p>}
			{selectedTab === 'Fan' && 'temp' in item && (
				<p className='fan_cap'>
					Cool Miner <span>{item.temp}°</span>
				</p>
			)}
			{(selectedTab === 'CPU' || selectedTab === 'GPU') && 'income' in item && (
				<p className='cpu_cap'>
					Profit: <span>+{item.income} C/Hr</span>
				</p>
			)}
			<div className='value'>
				<div className='border'>
					{(selectedTab === 'CPU' || selectedTab === 'GPU') && 'level' in item && <div className='level'>lvl {item.level}</div>}{' '}
					Cost: {balance >= item.cost ? <img src='/img/dollar.webp' alt='' /> : <img src='/img/silver.webp' alt='' />}
					{numberUtils.formatPriceWithDecimals(item.cost)}
				</div>
			</div>
		</SingleCardContainer>
	);
};

export default Card;
