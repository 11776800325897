import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TabContainer = styled.section(
	({ theme }) => css`
		border-radius: 18px;
		background: linear-gradient(0.92deg, rgba(255, 255, 255, 0.6) 60.24%, rgba(35, 135, 197, 0.27) 100.13%);
		padding: 1.5px;
		margin-bottom: 20px;

		.border {
			box-shadow: 0px 6px 12px 0px #2f7aa7 inset;
			position: relative;
			padding: 5px;
			display: grid;
			grid-template-columns: 1fr 2px 1fr 2px 1fr;
			background: #7ac0ed;
			border-radius: 16px;
			.tab_item {
				padding: 11px 0 9px;
				font-size: 16px;
				font-weight: 400;
				line-height: 19px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #487c9c;
				position: relative;

				&.active {
					background-color: #ffffff;
					border: 1px solid transparent;
					background-image: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
					background-origin: border-box;
					background-clip: padding-box, border-box;
					box-shadow: 0px 4px 4px 0px #ffffffb8 inset;
					color: ${theme.colors.text.secondary};
					border-radius: 14px;
					font-weight: 600;					
					padding:0;
					margin-left: 5px;
					margin-right: 5px;
					padding-top: 3px;
				}
				.remain_social_badge{
					position: absolute;
					right: 0;
					top: 0;
					background-color: #ff0000;
					color: #ffffff;
					border-radius: 50%;
					width: 12px;
					height: 12px;
					padding-top: 1px;
					font-size: 10px;
					font-weight: 600;
					margin-right: 5px;
					margin-top: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.divider {
				width: 2px;
				background-color: #ffffff;
				opacity: 0.2;
				height: 24px;
				margin-top: 10px;
 			}
		}
	`,
);
