import { createSlice } from '@reduxjs/toolkit';
import { ILevelResponse } from 'api/v2/levels';
import { ILevels } from 'store/types';

const initialState: ILevels[] = [];
export const Levels = createSlice({
	name: 'levels',
	initialState: initialState,
	reducers: {
		changeLevels: (state, action: { payload: ILevelResponse[] }) => {
			state.push(...action.payload);
 		},
	},
});

export const { changeLevels } = Levels.actions;
export default Levels.reducer;
