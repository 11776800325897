import { IFriendResponse } from 'api/v1/friend';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { CopyOutline, ProfileAddOutline, UnhappyOutline } from 'shared/components/icons';
import numberUtils from 'shared/utils/number';
import { FriendsListContainer } from '../css';

const FriendsList: FC<{ data: IFriendResponse }> = ({ data }) => {
	const onCopyClickHandler = () => {
		navigator.clipboard.writeText(data?.inviteLink).then(() => {
			toast.success('Copied to clipboard successfully!');
		});
	};
	return (
		<FriendsListContainer>
			<div className='header'>
				<h2>List of your friends (20 last friends) </h2>
			</div>

			{data.friends.length === 0 && (
				<div className='empty_container'>
					<p>You haven’t invited anyone yet.</p>
					<UnhappyOutline />
				</div>
			)}

			{data.friends.length > 0 && (
				<div className='list_container'>
					{data.friends.slice(-20).map((item, index) => (
						<div className='list_item_inner' key={`list_item_${index}`}>
							<div className='title'>
								<img src={`/img/pineye/Level_${item.level ?? 1}_.webp`} width={50} height={50} alt='' />
								<p>
									<span>{item.userName}</span>
									<span className='level_title'>{item.levelTitle}</span>
								</p>
							</div>
							<div className='value'>
								<img src='/img/dollar.webp' width={20} height={20} alt='' />
								<p>{numberUtils.formatPriceWithDecimals(item.balance)}</p>
							</div>
						</div>
					))}
				</div>
			)}

			<div className='invite_container'>
				<a className='invite_btn' href={'https://t.me/share/url?url=' + data.inviteLink + '&text=Play pineye airdrop telegram'}>
					<ProfileAddOutline />
					Invite a friend
				</a>
				<div className='copy_btn' onClick={onCopyClickHandler}>
					<CopyOutline />
				</div>
			</div>
		</FriendsListContainer>
	);
};

export default FriendsList;
