import { GETLevels, GETPlayers } from 'api/v2/levels';
import { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'shared/components/loader';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { Pineye, Ranking, TopRanks } from './components';
import { LeaguesContainer } from './css/leagues.style';
import { changeLevels } from 'store/reducers/levels';
import { changePlayers } from 'store/reducers/players';
import { useDispatch } from 'react-redux';

const Leagues = () => {
	const levels = useAppSelector(state => state.levels);
	const players = useAppSelector(state => state.players);
	const currentLevel = useAppSelector(state => state.profile.level);
	const dispatch = useDispatch();
	const [activeLevel, setActiveLevel] = useState<number>(currentLevel ?? 1);
	const [page, setPage] = useState<number>(1);
	const [loadingMore, setLoadingMore] = useState<boolean>(false);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const onChangeActiveLeague = (type: 'NEXT' | 'PREV') => {
		if (type === 'NEXT' && activeLevel < 10) {
			setActiveLevel(prev => prev + 1);
			setPage(1);
			setHasMore(true);
		}
		if (type === 'PREV' && activeLevel > 1) {
			setActiveLevel(prev => prev - 1);
			setPage(1);
			setHasMore(true);
		}
	};

	const loadPlayers = useCallback(() => {
		if (!loadingMore && hasMore) {
			setLoadingMore(true);
			GETPlayers(page, activeLevel)
				.then(res => {
					if (res && res.length > 0 && players.players !== null) {
						if (page === 1) {
							dispatch(changePlayers(res));
						} else {
							dispatch(changePlayers([...new Set([...players.players, ...res])]));
						}
						setPage(prev => prev + 1);
					} else {
						setHasMore(false);
					}
					setLoadingMore(false);
				})
				.catch(() => {
					setLoadingMore(false);
				});
		}
	}, [page, activeLevel, dispatch, players.players, loadingMore, hasMore]);

	useEffect(() => {
		GETLevels().then(res => {
			dispatch(changeLevels(res));
		});
		GETPlayers(1, activeLevel ?? 1).then(res => {
			dispatch(changePlayers(res));
		});
	}, [dispatch, currentLevel, activeLevel]);

	useEffect(() => {
		let debounceTimer: NodeJS.Timeout;

		const handleScroll = () => {
			if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight && !loadingMore) {
				clearTimeout(debounceTimer);
				debounceTimer = setTimeout(() => {
					loadPlayers();
				}, 100);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
			clearTimeout(debounceTimer);
		};
	}, [loadPlayers, loadingMore]);
	return (
		<LeaguesContainer>
			<Helmet>
				<title>PinEye | Leagues</title>
			</Helmet>

			{levels && currentLevel && players.players ? (
				<div className='main_container'>
					<Pineye data={levels} activeLevel={activeLevel} onChangeActiveLeague={onChangeActiveLeague} />
					<TopRanks data={players.players} />
					<Ranking players={players.players} data={levels} activeLevel={activeLevel} loadingMore={loadingMore} />
				</div>
			) : (
				<Loader />
			)}
		</LeaguesContainer>
	);
};

export default Leagues;
