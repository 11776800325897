import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PracticeModalContainer = styled.section(
	({ theme }) => css`
		padding: 0 24px 32px;
		position: relative;
		text-align: center;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;

		.calender {
			width: 125px;
			height: 135px;
			position: absolute;
			top: -150px;
			left: 0;
			right: 0;
			margin: auto;
		}

		.content {
			text-align: center;
			color: ${theme.colors.text.primary};
			display: flex;
			flex-direction: column;
			gap: 24px;
			padding-bottom: 16px;
			h3 {
				font-size: 32px;
				font-weight: 600;
				line-height: 26px;
				text-align: center;
			}

			p {
				font-size: 16px;
				font-weight: 400;
				line-height: 26px;
				text-align: center;
			}

			button {
				width: 100%;
				font-size: 18px;
				font-weight: 500;
				line-height: 22.28px;
				img {
					width: 20px;
					height: 20px;
					margin-bottom: 4px;
				}
			}
		}
	`,
);
