import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TimerContainer = styled.div(
	({ theme }) => css`
		color: ${theme.colors.text.primary};

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;

		.timer_scope {
			display: flex;
			gap: 4px;

			.timer_item {
				position: relative;
				width: 16px;
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					position: absolute;
					left: 0;
					top: 0;
					width: 16px;
					height: 24px;
				}

				.time {
					text-align: center;
					position: relative;
					z-index: 3;
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					margin-top: 2px;
				}
			}
		}

		.colon {
			margin-top: 1px;
			font-weight: 400;
			font-size: 28px;
			line-height: 34px;
		}
	`,
);
