import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GamesContainer = styled.div(
	({ theme }) => css`
		position: relative;
		height: 100vh;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 153px 1fr 1fr;
		color: ${theme.colors.text.primary};

		.background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100vh;
		}
		.header {
			position: relative;
			z-index: 1;
			display: grid;
			grid-template-columns: 1fr 2px 1.2fr 1px 1fr;
			grid-template-rows: 1fr 1fr;
			padding: 16px;
			justify-content: center;
			align-items: baseline;
			.level {
				display: flex;
				justify-content: center;
				gap: 7px;
				align-items: center;
				img {
					width: 29px;
				}
				.inner {
					display: flex;
					align-items: center;
					font-size: 11px;
					font-weight: 400;
					line-height: 13.34px;

					img {
						width: 15px;
						align-self: flex-start;
					}
				}
			}
			.dividerr {
				width: 1px;
				height: 32px;
				background: #ffffff30;
			}
			.divider-h {
				width: 28%;
				height: 1px;
				background: #ffffff30;
				position: absolute;
				top: 60px;
			}
			.sound {
				grid-column: 5/5;
				display: flex;
				justify-content: flex-end;
				width: inherit;
				img {
					width: 30px;
				}
			}
		}
		.man_container {
			position: relative;
			/* height: calc(100vh - 54%); */
			display: flex;
			justify-content: center;
			.man {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: 90%;
				z-index: 3;
			}
			.triangle {
				position: absolute;
				bottom: 0;
				z-index: 2;
				margin: auto;
				left: 0;
				right: 0;
				margin: auto;
				width: 90%;
			}
			.chakras {
				position: absolute;
				bottom: 0px;
				z-index: 4;
				margin: auto;
				left: 0;
				right: 0;
				margin: auto;
				width: 90%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-direction: column-reverse;
				gap: 17px;
				img {
					width: 32px;
					height: 32px;
					&.active {
						animation: glow_animation 2s infinite;
						width: 53px;
						height: 53px;
					}
				}
			}
		}
		.btn {
			position: relative;
			z-index: 2;
			width: 200px;
			height: 55px;
			transform: perspective(22px) rotateX(7deg);
			justify-self: center;
			width: 70%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			align-self: center;
			z-index: 2;
			position: relative;
			border-radius: 5px;
			.buda {
				width: 70px;
				position: absolute;
				top: -40px;
				right: 0;
				left: 0;
				transform: perspective(10px) rotateX(-5deg);
				margin: auto;
			}
			p {
				transform: perspective(10px) rotateX(-3deg);
				margin-top: 15px;
				font-size: 16px;
				font-weight: 500;
				line-height: 19.41px;
				text-align: center;
			}
		}
		.divider {
			width: 1px;
			height: 26px;
			background: #fff;
			margin: 0 10px;
		}
		@keyframes glow_animation {
			0% {
				scale: 0.95;
				filter: brightness(1.2);
			}
			50% {
				scale: 1.1;
				filter: brightness(1.9);
			}
			100% {
				scale: 0.95;
				filter: brightness(1.2);
			}
		}
		@media (max-height: 620px) {
			.btn {
				height: 40px;
				gap: 4px;
				margin-bottom: 16px;

				.buda {
					width: 58px;
				}
				p {
					font-size: 14px;
				}
			}
			.header {
				.level {
					img {
						width: 20px;
					}
					.inner {
						font-size: 9px;
						line-height: 10.9px;
						img {
							width: 10px;
						}
					}
				}
			}
			.man_container {
				.man {
					width: 80%;
				}
				.triangle {
					width: 80%;
				}
				.chakras {
					gap: 12px;
					img {
						width: 24px;
						height: 24px;
					}
				}
			}
		}
	`,
);
