import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TabsContainer = styled.div(
	() => css`
		position: relative;
		top: 130px; 
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		background: #b8e2fb;
		height: 42px;
		align-items: center;
		justify-content: center;
		justify-items: normal;
		border-radius: 8px;
		text-align: center;
		z-index: 3;
		.tab_item {
			color: #000;
			border-right: 2px solid #ffffff;
			font-size: 11px;
			font-weight: 400;
			line-height: 13.34px;
			height: 41px;
			padding: 0 14px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			cursor: pointer;
			&:last-child {
				border-right: none;
				&.active {
					padding: 0 18px;
				}
			}

			&.active {
				border: 2px solid #ffffff;
				background: #1187d0;
				box-shadow: 0px -14px 23px 0px #00000040 inset;
				z-index: 2;
				border-radius: 8px;
				color: #ffffff;
			}
			&.without_border {
				border-right: none;
			}
		}

		@media (max-width: 344px) {
			top: 100px;
			.tab_item {
				padding: 10px;
				font-size: 10px;
				&.active {
					padding: 11px;
				}
			}
		}
	`,
);
