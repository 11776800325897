import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BuyModalContainer = styled.section(
	({ theme }) => css`
padding: 0 24px 32px;
position: relative;
text-align: center;
color: ${theme.colors.text.primary};
display: flex;
flex-direction: column;

 .calender {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -130px;
    left: 0;
    right: 0;
    margin: auto;
}
.issue_pic{
    width: 100px;
    height: 100px;
    position: absolute;
    top: -120px;
    left: 0;
    right: 0;
    margin: auto;
}
.content{
    text-align: center;
    color: ${theme.colors.text.primary};
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    h3{
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;

        }
    .yellow_text{
        font-size: 16px;
        font-weight: 400;
        line-height: 14px;
        text-align: center;
        color: #FFEC2F;
        }
    .blue_text{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        color: #03DCCE;
        border-left:1.5px solid #00C5EB;
        border-right:1.5px solid #00C5EB;
        padding : 0 4px;
        margin: 0 4px;
    }  
    .green_text{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        color: #9AFF86;
    }  
    p{
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
    }
 
    .btn {
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        line-height: 22.28px;
        img{
            width:20px;
            height:20px;
            margin-bottom:4px;
            }
        }
}
@media (max-height: 667px) {
    padding: 0 24px 10px;
    gap: 16px;
    .calender {
        width: 130px;
        height: 130px;
        top: -120px;
    }
    .content{
        height: 200px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        h3 {
            padding-top:20px;
        line-height: 20px;
        font-size: 20px;
        }
        p {
        line-height: 18px;
        font-size: 12px:
        }
       
    }
}
`,
);
