import { FC } from 'react';
import Modal from 'shared/components/modal';
import { InfoModalContainer } from './info-modal.style';
import useCloseModal from 'shared/hooks/use-close-modal';
import Button from 'shared/components/button';

interface IInfoMoalProps {
	status: boolean;
	setStatus: (status: boolean) => void;
}

const InfoModal: FC<IInfoMoalProps> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<InfoModalContainer>
				<img src={'/img/secret-code/info.webp'} className='calender' />

				<div className='content'>
					<h3>Unlock the Secret Code</h3>
					<p>
						Here's a valuable hint to help you uncover the secret code! Follow our channels on Telegram, YouTube, Instagram, and
						Twitter closely to discover even more clues.
					</p>

					<Button
						variant='primary'
						onClick={() => {
							onClose();
						}}
					>
						Continue
					</Button>
				</div>
			</InfoModalContainer>
		</Modal>
	);
};

export default InfoModal;
