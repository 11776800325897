import { createSlice } from '@reduxjs/toolkit';
import { IGetPlayersResponse } from 'api/v2/levels';
import { IPlayers } from 'store/types';

const initialState: IPlayers  = {
	players:null,
};

export const Players = createSlice({
	name: 'players',
	initialState: initialState,
	reducers: {
		changePlayers: (state, action: { payload: IGetPlayersResponse[] }) => {
			state.players = action.payload;
		},
	},
});

export const { changePlayers } = Players.actions;

export default Players.reducer;
