import React from 'react';
import styled from '@emotion/styled';

interface IGradientCircleProgressbarProps {
	percentage: number;
	width?: number;
	strokeWidth?: number;
	fontSize?: string;
	fontColor?: string;
	fontFamily?: string;
	primaryColor?: [string, string];
	secondaryColor?: string;
	fill?: string;
	hidePercentageText?: boolean;
	strokeLinecap?: 'butt' | 'round' | 'square';
	children?: React.ReactNode;
}

const CircleContainer = styled.div`
	display: inline-block;
	border-radius: 100%;
	position: relative;
`;

const PercentageContainer = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
`;

const StyledCircle = styled.circle`
	transform-origin: 50% 50%;
`;

const GradientCircleProgressbar: React.FC<IGradientCircleProgressbarProps> = ({
	percentage,
	width = 200,
	strokeWidth = 5,
	fontSize = 'inherit',
	fontColor = 'inherit',
	fontFamily = 'inherit',
	primaryColor = ['#00BBFF', '#92d7f1'],
	secondaryColor = 'transparent',
	fill = 'transparent',
	hidePercentageText = false,
	strokeLinecap = 'round',
	children,
}) => {
	const PI = 3.14;
	const R = width / 2 - strokeWidth * 2;
	const circumference = 2 * PI * R;
	const offset = circumference - (percentage / 100) * circumference;
	const gradientId = `${primaryColor[0]}${primaryColor[1]}`.replace(/#/g, '');

	return (
		<CircleContainer style={{ height: `${width}px`, width: `${width}px` }}>
			{!hidePercentageText && (
				<PercentageContainer>
					{children ? (
						children
					) : (
						<span
							style={{
								fontSize,
								fontFamily,
								color: fontColor,
							}}
						>
							{percentage}%
						</span>
					)}
				</PercentageContainer>
			)}

			<svg width='100%' height='100%' version='1.1' xmlns='http://www.w3.org/2000/svg'>
				<linearGradient id={gradientId} x1='0%' y1='0%' x2='100%' y2='100%'>
					<stop offset='0%' stopColor={primaryColor[0]} />
					<stop offset='100%' stopColor={primaryColor[1]} />
				</linearGradient>
				<circle
					strokeWidth={strokeWidth}
					fill='transparent'
					r={R}
					cx={width / 2}
					cy={width / 2}
					stroke={secondaryColor}
					strokeDasharray={`${circumference} ${circumference}`}
				/>
				<StyledCircle
					strokeWidth={strokeWidth}
					fill={fill}
					r={R}
					cx={width / 2}
					cy={width / 2}
					stroke={`url(#${gradientId})`}
					strokeLinecap={strokeLinecap}
					strokeDasharray={`${circumference} ${circumference}`}
					strokeDashoffset={offset}
					transform='rotate(-90, 0, 0)'
				/>
			</svg>
		</CircleContainer>
	);
};

export default GradientCircleProgressbar;
