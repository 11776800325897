import { CloseOutline } from 'shared/components/icons';
import { BuyModalContainer } from '../css';
import numberUtils from 'shared/utils/number';
import { ILotteryResponse, POSTBuyTicketAPI } from 'api/v2/lottery';
import { changeBalance } from 'store/reducers/profile';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import Button from 'shared/components/button';

const BuyModal = ({
	isOpen,
	onClose,
	cost,
	count,
	setHasBuyed,
	setCodes,
	isLoading,
	setIsLoading,
}: {
	data: ILotteryResponse;
	setHasBuyed: (hasBuyed: boolean) => void;
	setCodes: (codes: string[]) => void;
	setIsLoading: (isLoading: boolean) => void;
	isLoading: boolean;
	count: number;
	isOpen: boolean;
	onClose: () => void;
	cost: number | null;
}) => {
	const dispatch = useDispatch();

	if (!isOpen) return null;
	const onBuyTicketClickHandler = () => {
		setIsLoading(true);
		POSTBuyTicketAPI(count)
			.then(res => {
				window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
				toast.success('Your Ticket has successfully registered!');
				dispatch(changeBalance({ balance: res.balance }));
				setHasBuyed(true);
				setCodes(res.codes);
			})

			.finally(() => {
				setIsLoading(false);
			});
	};
	return (
		<BuyModalContainer onClick={onClose}>
			<div className='border'>
				<div className='modal_content'>
					<CloseOutline className='close_icon' onClick={onClose} />
					<div className='img_container'>
						<img
							src={
								count === 1
									? '/img/lottery/one-ticket.webp'
									: count === 3
									? '/img/lottery/three-ticket.webp'
									: count === 5
									? '/img/lottery/five-ticket.webp'
									: '/img/lottery/ten-ticket.webp'
							}
							alt=''
						/>
					</div>
					<h2>Purchase your ticket</h2>
					<p className='text'>
						{count === 1
							? 'Take your shot! Buy a single ticket for 1,000	coins and put your luck to the test.'
							: ` Enhance your odds by ${count}x for just ${
									cost !== null ? numberUtils.formatPriceWithDecimals(cost * count) : '1000'
							  } coins! Buy ${count} tickets and significantly
						increase your chance to win.`}
					</p>
					<div className='number_container'>
						<div className='number_item'>{'?'}</div>
						<div className='number_item'>{'?'}</div>
						<div className='number_item'>{'?'}</div>
						<div className='number_item'>{'?'}</div>
						<div className='number_item'>{'?'}</div>
						<div className='number_item'>{'?'}</div>
						<div className='number_item'>{'?'}</div>
					</div>
					<Button id='buy_lottery_ticket' variant='primary' disabled={isLoading} onClick={onBuyTicketClickHandler}>
						Buy {count === 1 ? 'a' : ''} Ticket{count !== 1 ? 's' : ''} ({' '}
						<img width={'13px'} height={'13px'} src={`/img/coin/pineye.webp`} alt='' />
						{cost !== null ? numberUtils.formatPriceWithDecimals(cost * count) : '1000'})
					</Button>
				</div>
			</div>
		</BuyModalContainer>
	);
};
export default BuyModal;
