import { FC } from 'react';

import Picker from 'react-mobile-picker';

const selections = [
	<img src='/img/secret-code/1.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/2.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/3.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/4.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/5.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/6.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/7.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/8.webp' alt='' className='picker_img' />,
	<img src='/img/secret-code/9.webp' alt='' className='picker_img' />,
];

interface IMyPickerProps {
	pickerValue: {
		val1: string;
		val2: string;
		val3: string;
		val4: string;
	};
	setPickerValue: (value: { val1: string; val2: string; val3: string; val4: string }) => void;
}
export const MyPicker: FC<IMyPickerProps> = ({ pickerValue, setPickerValue }) => {
	return (
		<Picker itemHeight={57} className='picker' value={pickerValue} onChange={setPickerValue}>
			{[1, 2, 3, 4].map(col => (
				<Picker.Column className='column' key={`val-${col}`} name={`val${col}`}>
					{selections.map((option, index) => (
						<Picker.Item className='items' key={`${index}-option`} value={(index + 1).toString()}>
							{option}
						</Picker.Item>
					))}
				</Picker.Column>
			))}
		</Picker>
	);
};
