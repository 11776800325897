import { Helmet } from 'react-helmet';
import { PracticeContainer } from './css';
import Button from 'shared/components/button';

import { GETClaimPractice, IGetWeeklyPractice, GETWeeklyPractice } from 'api/v1/prana-game';

import { useEffect, useRef, useState } from 'react';

import { InfoModal, Timer, TimerFixed, CongratModal } from './components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeBalance, changeReplay } from 'store/reducers/profile';
import { changePranaProit } from 'store/reducers/prana';

const Practice = () => {
	const [practiceData, setPracticeData] = useState<IGetWeeklyPractice['practices'][0]['days'][0]>();
	const [start, setStart] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [end, setEnd] = useState(false);
	const [letStart, setLetStart] = useState(false);
	const [count, setCount] = useState<number | null>(null);
	const [congratModal, setCongratModal] = useState(false);
	const [sound, setSound] = useState(true);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const audioRef = useRef(null);

	const id = window.location.pathname.split('/').pop();
	useEffect(() => {
		if (sound && audioRef.current) {
			(audioRef.current as HTMLAudioElement).play();
		} else if (!sound && audioRef.current) {
			(audioRef.current as HTMLAudioElement).pause();
			(audioRef.current as HTMLAudioElement).currentTime;
		}
	}, [sound]);
	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/games');
		});
		GETWeeklyPractice().then(res => {
			if (id) {
				const filteredPractice = res.practices[0].days.filter(i => i.id === +id);
				if (filteredPractice.length > 0) {
					setPracticeData(filteredPractice[0]);
				}
			}
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);
	const handleClose = () => {
		if (end) {
			navigate('/games');
		} else {
			setWarningModal(true);
		}
	};

	useEffect(() => {
		if (letStart) {
			let n = 3;
			const interval = setInterval(() => {
				if (n > 0) {
					setCount(n);
					n--;
				} else {
					clearInterval(interval);
					setLetStart(false);
					setStart(true);
					setCount(null);
				}
			}, 1000);
		}
		return () => {};
	}, [letStart]);
	return (
		<PracticeContainer>
			<Helmet>
				<title>PinEye | prana Practice</title>
			</Helmet>
			<audio ref={audioRef} src='/audio/Prana.mp3' loop muted={!sound} style={{ display: 'none' }} />

			<img className='background' src='/img/prana/background.webp' alt='' />
			<div className='head'>
				<div className='close' onClick={() => handleClose()}>
					<img src={'/img/prana/close.webp'} alt='' />
				</div>
				<div className='sound' onClick={() => setSound(!sound)}>
					{sound ? <img src='/img/game/sound.webp' alt='' /> : <img src='/img/game/sound-off.webp' alt='' />}
				</div>
			</div>
			{letStart && (
				<div className='counter_container'>
					<div className='lets_start'>{count}</div>
				</div>
			)}

			<div className='man_container'>
				<img className='man' src='/img/prana/man.webp' alt='' />
				<p className='in'>Inhale</p>
				<p className='hold'>Hold</p>
				<p className='ex'>Exhale</p>
				{start && (
					<video className='triangle' loop muted playsInline autoPlay={start}>
						<source src='/video/light-low.mov' type='video/mp4; codecs="hvc1"' />
						<source src='/video/light.webm' type='video/webm' />
					</video>
				)}
			</div>
			{practiceData && (
				<div className='timer'>
					{!start && !end && <TimerFixed date={practiceData.practiceTime * 1000} />}
					{start && (
						<Timer
							setEnd={() => {
								setEnd(true);
								setStart(false);
							}}
							date={practiceData.practiceTime * 1000}
						/>
					)}
				</div>
			)}

			<Button
				variant='primary'
				className='btn'
				disabled={start}
				onClick={() => {
					if (end) {
						setCongratModal(true);
						GETClaimPractice()
							.then(res => {
								dispatch(changeBalance(res));
								dispatch(changePranaProit(res));
								dispatch(changeReplay());
							})
							.then(() => {
								setEnd(false);
								setStart(false);
							});
					} else {
						setLetStart(true);
						setTimeout(() => {
							setStart(true);
						}, 4000);
					}
				}}
			>
				{end ? <p>Claim</p> : <p>{start ? 'Claim' : 'Start'}</p>}
			</Button>
			{warningModal && <InfoModal status={warningModal} setStatus={setWarningModal} />}
			{congratModal && <CongratModal status={congratModal} setStatus={setCongratModal} />}
		</PracticeContainer>
	);
};
export default Practice;
