import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const OverviewContainer = styled.section(
	({ theme }) => css`
		display: grid;
		grid-template-columns: 1fr 1px 1fr 1px 1fr;
		grid-template-rows: 1fr ;
		padding: 15px  ;
		gap:3px;
		position: relative;
		.divider {
			width: 1px;
			height: 26px;
			background-color: #ffffff73;
		}

		.card_item_container {
			position: relative;
			z-index: 2;
			text-align: left;
			/* height: 40px; */
			display: flex;
			align-items: center;
			justify-content: center;

			.card_item {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 6px;
				color: ${theme.colors.text.primary};
				.pic {
					width: 29px;
					height: 29px;
					&.right {
						width: 32px;
						height: 32px;
						margin-bottom: -4px;
					}
				}
				.text_container {
					display: flex;
					padding-top: 3px;
					justify-content: center;
					/* flex-direction: column;
					gap: 2px;
					align-items: left;
					justify-content: center; */
					/* .title {
						font-size: 15px;
						font-weight: 400;
						line-height: 18.57px;
					} */
					.value {
						font-size: 13px;
						font-weight:700;
						line-height: 16.09px;
						span{
						 
							font-weight: 400;
							 
						}
					}
				}
			}
		}
	`,
);
