import { useEffect, useState } from 'react';

export const useCountDownHours = (targetDate: number = 0) => {
	const [countDown, setCountDown] = useState<number>(targetDate);

	useEffect(() => {
		const myInterval = setInterval(() => {
			setCountDown(countDown - 1000);
		}, 1000);
		if (+hours <= 0 && +minutes <= 0 && +seconds <= 0) {
			clearInterval(myInterval);
		}
		return () => {
			clearInterval(myInterval);
		};
	});
	let hours: number | string = Math.floor(countDown / (1000 * 60 * 60));
	let minutes: number | string = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	let seconds: number | string = Math.floor((countDown % (1000 * 60)) / 1000);

	if (hours < 0) {
		hours = 0;
	}
	if (minutes < 0) {
		minutes = 0;
	}
	if (seconds < 0) {
		seconds = 0;
	}

	const setNewCountDown = (state: number) => {
		setCountDown(state);
	};

	if (hours <= 9) {
		hours = `0${hours}`;
	}
	if (minutes <= 9) {
		minutes = `0${minutes}`;
	}
	if (seconds <= 9) {
		seconds = `0${seconds}`;
	}

	return { hours, minutes, seconds, setNewCountDown };
};
