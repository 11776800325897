import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IncomeStatsContainerProps {
	filledPercentage: number;
	dividerCount: number;
}
export const IncomeStatsContainer = styled.div<IncomeStatsContainerProps>(
	({ theme, filledPercentage, dividerCount }) => css`
		color: ${theme.colors.text.primary};
		margin-bottom: 8px;
		display: grid;
		grid-template-columns: 1fr;
		column-span: all;
		gap: 16px;
		background: #3993cb;
		border: 1.5px solid transparent;
		border-radius: 21px;
		background-image: linear-gradient(#3993cb, #3993cb),
			linear-gradient(60.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
		background-origin: border-box;
		background-clip: padding-box, border-box;
		padding: 15px 14px 18px;
		position: relative;
		.light {
			width: 80%;
			height: 70px;
			position: absolute;
			left: 2vw;
			top: 2vh;
			background: #ffffff94;
			filter: blur(70px);
			border-radius: 80px;
		}
		.reward_item {
			background: #005385;
			border: 1.5px solid transparent;
			border-radius: 11px;
			background-image: linear-gradient(#005385, #005385),
				linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			background-origin: border-box;
			background-clip: padding-box, border-box;
			display: flex;
			align-items: center;
			padding: 11px 20px;
			gap: 12px;
			justify-content: center;
			img {
				width: 48px;
				height: 48px;
				z-index: 1;
				position: relative;
			}
			.value {
				font-size: 26px;
				font-weight: 600;
				line-height: 18px;
				display: flex;
				flex-direction: column;
				gap: 10px;
				z-index: 1;
				position: relative;
				.title {
					font-size: 14px;
					font-weight: 400;
					line-height: 18px;
					text-align: center;
				}
			}
		}
		.friends {
			background: #005385;
			border: 1.5px solid transparent;
			color: ${theme.colors.text.primary};

			border-radius: 11px;
			background-image: linear-gradient(#005385, #005385),
				linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			background-origin: border-box;
			background-clip: padding-box, border-box;
			display: grid;
			align-items: center;
			padding: 11px 20px;
			gap: 12px;
			z-index: 1;
			grid-template-columns: 60px 1fr auto;
			img {
				width: 48px;
				height: 48px;
				z-index: 1;
				position: relative;
			}
			.title {
				font-size: 14px;
				font-weight: 600;
				line-height: 17.33px;
			}
			.value {
				font-size: 24px;
				font-weight: 600;
				line-height: 29.71px;
				letter-spacing: 0.06em;
				display: flex;
				justify-self: flex-start;
			}
			p {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				strong {
					font-size: 16px;
				}
			}
			.link {
				display: flex;
				align-items: center;
				gap: 4px;
				font-size: 14px;
				font-weight: 400
				line-height: 17px;
				color: ${theme.colors.text.primary};
				border-radius:9px ;
				padding:1px;
				width: -webkit-fill-available;
 				.link_text{
					width: -webkit-fill-available;
					background: #3589BC;
					padding:  8px 12px;
					border-radius:8px ;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 4px;
					a {
						color: ${theme.colors.text.primary};
						text-decoration: none;
					}
				}
			}
		}
		.invite{
			display: grid;
			grid-template-columns: 77px auto;
			align-items: center;
			background: #005385;
			border: 1.5px solid transparent;
			border-radius: 11px;
			background-image: linear-gradient(#005385, #005385),
				linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			background-origin: border-box;
			background-clip: padding-box, border-box;
			padding: 11px 20px;

			img {
				width: 70px;
				height: 70px;
				z-index: 1;
				position: relative;
			}
			.text{
				display: flex;
				flex-direction: column;
				gap:10px;
				p{
						font-size: 14px;
						font-weight: 600;
						line-height: 17.33px;
					}
				.last_line{
					display: flex;
					align-items: center;
					gap: 4px;
					justify-content: space-between;
				
					.reward{
						display: flex;
						align-items: center;
						gap: 4px;
						font-size: 18px;
						font-weight: 600;
						line-height: 22.28px;
						color: #A1FFF9;
						img{
							width: 19px;
							height: 19px;
							margin-bottom: 3px;
						}
					}
					.idk{
						font-size: 12px;
						font-weight: 600;
						line-height: 14.86px;
 
					}
				}
			}
			.progress_bar {
  width: -webkit-fill-available;
  height: 14px;
  border-radius: 3px;
  position: relative;

  .border {
    width: 100%;
    height: 12px;
    border-radius: 2px;
    background: #0958a8;
    box-shadow: 0px 2px 4px 0px #ffffffc2 inset, 0px 0px 6px 0px #000000;
    position: relative;
    overflow: hidden;  
  }

  .filled {
	height: 100%;
    border-radius: 1px;
    width: ${filledPercentage}%;
    transition: all 300ms ease;
    position: relative;
    background: linear-gradient(
      90deg,
      #6800d0 7.01%,
      #902bf5 31.49%,
      #5e6cff 63.44%,
      #54beff 97.94%
    );
    box-shadow: 0px 2px 4px 0px #ffffff inset, 0px 4px 4px 0px #002fec24;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 98%;
      background: repeating-linear-gradient(
        to right,
        transparent,
        transparent calc(100% / ${dividerCount} - 1px),
        #ffffff50 calc(100% / ${dividerCount} - 1px),
        #ffffff50 calc(100% / ${dividerCount})
      );
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      right: -3.5px;
      top: -3px;
      width: 7px;
      height: 18px;
      border-radius: 3px;
      background: linear-gradient(178.94deg, #79ccff 0.91%, #3b9fdd 58.31%);
      box-shadow: 0px 0px 4px 0px #0000008f;
    }
  }
}

		}
	`,
);
